import gql from 'graphql-tag';

// eslint-disable-next-line import/prefer-default-export
export const studyDicomDataFrgmnt = gql`
  fragment studyDicomDataFrgmnt on StudyDicomData {
    study_uid
    exam_timestamp
    error_message
  }
`;

export interface StudyDicomFrgmntType {
  study_uid: string;
  exam_timestamp: string;
  error_message: string | undefined;
}
