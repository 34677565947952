import { SAVE_LABEL, DELETE_LABEL, LabelActions, SAVE_LABELS } from '../../actions/types';
import { LabelState } from '../types';
import saveLabelReducer from './saveLabelReducer';
import deleteLabelReducer from './deleteLabelReducer';
import saveLabelsReducer from './saveLabelsReducer';

export default (state: LabelState = {}, action: LabelActions) => {
  switch (action.type) {
    case SAVE_LABEL: {
      const { taskId, tool } = action;

      return saveLabelReducer({ taskId, tool, state });
    }
    case SAVE_LABELS: {
      const { taskId, tools } = action;

      return saveLabelsReducer({ taskId, tools, state });
    }
    case DELETE_LABEL: {
      const { taskId, tool } = action;

      return deleteLabelReducer({ taskId, tool, state });
    }
    default:
  }
  return state;
};
