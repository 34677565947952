interface SingleEnv {
  API_URL: string;
  GRAPHQL_URI: string;
  SUBSCRIPTION_URI: string;
  // Warning if the value is less than retrieve it will delete the current task
  no_tasks_2_store: number;
  no_tasks_2_preload: number;
  no_tasks_2_retrieve: number;
  no_image_load_retries: number;
}

interface AllEnv {
  development: SingleEnv;
  production: SingleEnv;
  test: SingleEnv;
}

export type EnvironmentTypes = keyof AllEnv;

const env: AllEnv = {
  development: {
    API_URL: 'http://%replace%',
    GRAPHQL_URI: 'http://%replace%/graphql',
    SUBSCRIPTION_URI: 'ws://%replace%/graphql',
    no_tasks_2_retrieve: 2,
    no_tasks_2_preload: 3,
    no_tasks_2_store: 10,
    no_image_load_retries: 5,
  },
  production: {
    API_URL: 'https://%replace%',
    GRAPHQL_URI: 'https://%replace%/graphql',
    SUBSCRIPTION_URI: 'wss://%replace%/graphql',
    no_tasks_2_retrieve: 2,
    no_tasks_2_preload: 3,
    no_tasks_2_store: 10,
    no_image_load_retries: 5,
  },
  test: {
    API_URL: 'https://fake_url',
    GRAPHQL_URI: 'https://fake_url/graphql',
    SUBSCRIPTION_URI: 'wss://fake_url/graphql',
    no_tasks_2_retrieve: 2,
    no_tasks_2_preload: 3,
    no_tasks_2_store: 10,
    no_image_load_retries: 5,
  },
};

export default env;
