import getPerpendicularPoints, { PerpProps } from './getPerpendicularPoints';
import { XYpos } from '../types/XYpos';

export interface DrawTlineProps extends PerpProps {
  ctxt: CanvasRenderingContext2D;
  scale: XYpos;
}

const drawTline = ({ ctxt, rulerLength, scale, ...props }: DrawTlineProps) => {
  const { from, to } = getPerpendicularPoints({
    rulerLength: rulerLength / Math.max(scale.x, scale.y),
    ...props,
  });

  ctxt.setLineDash([2, 3]);
  ctxt.beginPath();
  ctxt.moveTo(from.x, from.y);
  ctxt.lineTo(to.x, to.y);
  ctxt.stroke();
  ctxt.closePath();
  ctxt.setLineDash([]);
};

export default drawTline;
