import * as React from 'react';
import { QueryResult, useQuery, QueryHookOptions } from '@apollo/client';
import gql from 'graphql-tag';
import {
  userFrgmnt,
  workerFrgmnt,
  currentTaskFrgmnt as taskFrgmnt,
  CurrenTaskFrgmntType as TaskFrgmntType,
  SITHSFrgmntType,
  CurrentUserType,
  sithsFrgmnt,
} from './fragments';

export const node = gql`
  query currentUser {
    user {
      current {
        ...userFrgmnt
        worker {
          ...workerFrgmnt
          current_task {
            ...taskFrgmnt
          }
        }
      }
      SITHS {
        ...sithsFrgmnt
      }
    }
  }
  ${userFrgmnt}
  ${workerFrgmnt}
  ${taskFrgmnt}
  ${sithsFrgmnt}
`;

interface QueryWrapper<P> {
  user: {
    current: P | undefined;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    SITHS: SITHSFrgmntType;
  };
}

export type QueryRsltsType = QueryResult<QueryWrapper<CurrentUserType>, {}>;

export type CurrentUserContextType = QueryRsltsType & {
  current: CurrentUserType | undefined;
  current_task: TaskFrgmntType | undefined;
  siths: SITHSFrgmntType | undefined;
};

export const CrntUsrCtxt = React.createContext<CurrentUserContextType | undefined>(undefined);

export function retrieveTaskData(res: QueryRsltsType | undefined): TaskFrgmntType | undefined {
  if (!res) return undefined;

  const { data } = res;
  if (!data || !data.user || !data.user.current || !data.user.current.worker) {
    return undefined;
  }

  const { current_task: task } = data.user.current.worker;
  return task;
}

export const useCurrentUserCtxt = () => {
  const ctxt = React.useContext(CrntUsrCtxt);
  if (!ctxt) {
    return { user: undefined, currentTask: undefined, siths: undefined };
  }
  const { current: user, current_task: currentTask, siths } = ctxt;
  return { user, currentTask, siths };
};

export default (options?: QueryHookOptions<QueryWrapper<CurrentUserType>, {}>) =>
  useQuery(node, { fetchPolicy: 'no-cache', ...options });
