export interface ViewerTheme {
  background: string;
  color: string;
  borderClr: string;
  reportBackground: string;
  reportBorder: string;
  headerBackground: string;
  headerColor: string;
  navbarBgClr: string;
  navbarClr: string;
  navbarBottomBorder: string;
  navbarBrandColor: string;
  navbarBrandColorHover: string;
  viewerToolbarBorder: string;
  viewerToolbarBackground: string;
  classificationBorder: string;
  classificationBackground: string;
  profileBurger: string;
  profileBackground: string;
  profileColor: string;
  thumbnailButtons: {
    selected: string;
    unselected: string;
    selectedInvalid: string;
  };
}

export type ThemeTypes = 'Batmode' | 'Sunshine';

type ThemeContainer = { [key in ThemeTypes]: ViewerTheme };

const themes: ThemeContainer = {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  Batmode: {
    background: '#090217',
    color: '#fff',
    borderClr: '#151F4F',
    reportBackground: '#13141B',
    reportBorder: '#31333E',
    headerBackground: '#383E57',
    headerColor: '#fff',
    navbarBgClr: '#383E57',
    navbarClr: '#fff',
    navbarBottomBorder: '#000',
    navbarBrandColor: 'rgba(0, 0, 0, 0.75)',
    navbarBrandColorHover: 'rgba(255, 255, 255, 0.75)',
    viewerToolbarBorder: '#090217',
    viewerToolbarBackground: '#090217',
    classificationBorder: '#2b366b',
    classificationBackground: '#455083',
    profileBurger: '#fff',
    profileBackground: '#383E57',
    profileColor: '#fff',
    thumbnailButtons: {
      unselected: 'rgba(0, 0, 0, 0)', // Invisible border
      selected: '#ECE094',
      selectedInvalid: '#9e3737',
    },
  },
  // eslint-disable-next-line @typescript-eslint/naming-convention
  Sunshine: {
    background: '#fff',
    color: '#222',
    borderClr: '#aaa',
    reportBackground: '#fff',
    reportBorder: '#aaa',
    headerBackground: '#c9d7ed',
    headerColor: '#222',
    navbarBgClr: '#f5f5f5',
    navbarClr: '#222',
    navbarBottomBorder: '#ccc',
    navbarBrandColor: 'rgba(50, 50, 50, 0.75)',
    navbarBrandColorHover: 'rgba(0, 0, 0, 0.75)',
    viewerToolbarBorder: '#eee',
    viewerToolbarBackground: '#fff',
    classificationBorder: '#17a2b8',
    classificationBackground: 'inherit',
    profileBurger: '#932c67',
    profileBackground: '#932c67',
    profileColor: '#fff',
    thumbnailButtons: {
      unselected: 'rgba(0, 0, 0, 0)', // Invisible border
      selected: '#17a2b8',
      selectedInvalid: '#9e3737',
    },
  },
};

export default themes;
