import { Action as ReduxAction } from 'redux';

export const TOOLBAR_MAXIMIZE = 'TOOLBAR_MAXIMIZE';
export const TOOLBAR_MINIMIZE = 'TOOLBAR_MINIMIZE';
export const TOOLBAR_MAXIMIZE_TOGGLE = 'TOOLBAR_MAXIMIZE_TOGGLE';

export interface ToolbarMaximizeAction extends ReduxAction {
  type: 'TOOLBAR_MAXIMIZE';
}

export interface ToolbarMinimizeAction extends ReduxAction {
  type: 'TOOLBAR_MINIMIZE';
}

export interface ToolbarToggleAction extends ReduxAction {
  type: 'TOOLBAR_MAXIMIZE_TOGGLE';
}

export type ToolbarActions = ToolbarMaximizeAction | ToolbarMinimizeAction | ToolbarToggleAction;
