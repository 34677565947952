/* eslint-disable no-underscore-dangle */
import { produce } from 'immer';
import Base, {
  Props as BaseProps,
  Options as BaseOptions,
  CoreData as BaseCoreData,
} from '../Base';
import {
  SuggestedLabelsFrgmntType as SuggestedLabels,
  ClassificationProbability,
} from '../../../gql/fragments/task2label/suggestedLabels';
import getGroups from './getGroups';
import initData from '../helpers/initData';
import {
  MrkrChoice,
  MrkrChoiceGroup,
  MrkrCfgOptionsClassifications,
} from '../../../gql/fragments/task2label/markers';
import unique from '../../unique';
import { SuggestedClassification } from '../../../types';
import { probabilitySelectionThreshold } from '../../../config';

interface ClassificationSubmission {
  classes: string[];
  short_description: string;
}

interface ClassOptions {
  max?: number;
  min?: number;
  choices: MrkrChoice[];
  // eslint-disable-next-line camelcase
  choice_groups: MrkrChoiceGroup[];
}

interface ChoiceGroups {
  name: string;
  key: string;
  choices: {
    selected: boolean;
    short_description: string;
    detailed_description: string;
    display_name: string;
    lastWithShared: string | undefined;
  }[];
}

export interface SuggestedChoice extends MrkrChoice {
  probability: number;
}

interface GroupSelections {
  [key: string]: string[];
}

// eslint-disable-next-line
interface ClassificationOptions extends BaseOptions, ClassOptions {
  // Only inherited elements
}

export interface Props extends BaseProps {
  classes?: string[];
  options: ClassificationOptions;
  groupSelections?: GroupSelections;
  touchedChoiceShortDescs?: string[];
}

export interface CoreData extends BaseCoreData {
  [key: string]: unknown;
  classes: string[];
  groupSelections: GroupSelections;
  suggestedClasses: ClassificationProbability[] | undefined;
  options: MrkrCfgOptionsClassifications;
  // We need to know if an element has been modified so that
  // we can figure out if the suggested label should be active
  touchedChoiceShortDescs: string[];
  type: 'Classification';
  imageMrkr: false;
}

export interface Choice2display {
  selected: boolean;
  short_description: string;
  detailed_description: string | undefined;
  display_name: string;
  lastWithShared: string | undefined;
}

export interface GroupedChoices {
  name: string;
  key: string;
  choices: Choice2display[];
}

interface CachedClasses {
  cache?: string[];
  classes?: string[];
  suggestedChoices?: SuggestedChoice[];
}

const undefinedGroup = Symbol('Touched group without definition');

const initTemplate = {
  classes: [],
  groupSelections: {},
  suggestedClasses: null,
  touchedChoiceShortDescs: [],
};

export default class Classification extends Base<Props, CoreData> {
  constructor(props: Props | Classification) {
    super(props, 'Classification');

    // Initi new object
    if (!(props instanceof Classification)) {
      const { options } = props;
      if (!options) throw new Error('Classification requires options');
      if (!options.choices) throw new Error('You must provide an adequate option with choices');
      this.data = initData(this.data, initTemplate);
    }

    this.choiceMap = {};
    this.options.choices.forEach((c) => {
      this.choiceMap[c.short_description] = c;
    });
  }

  private choiceMap: { [index: string]: MrkrChoice };

  get classes() {
    return this.data.classes;
  }

  get groupSelections() {
    return this.data.groupSelections;
  }

  get suggestedClasses() {
    return this.data.suggestedClasses;
  }

  get options() {
    return this.data.options;
  }

  // @ts-ignore - clone doesn't really work with inerhitance and self return
  clone(): Classification {
    const clone: Classification = super.clone();
    clone.data = produce<CoreData>(this.data, (draft) => {
      draft.classes = [...draft.classes];
    });
    return clone;
  }

  getChoiceGroup(selection: MrkrChoice | string) {
    const choice = this.getChoice(selection);
    if (!choice) return undefined;
    if (!choice.group_name) return undefined;

    return this.getGroups().find((c) => c.name === choice.group_name);
  }

  getGroups(activator?: string) {
    return getGroups({
      activator,
      choiceGroups: this.options.choice_groups,
      choices: this.options.choices,
    });
  }

  getActiveGroups(): (MrkrChoiceGroup & { members: MrkrChoice[]; selected: MrkrChoice[] })[] {
    const groups = this.getGroups();

    const classes = this.getClasses();
    return groups
      .filter((grp) =>
        grp.activating_short_descriptions.find((sd) => !!classes.find((c) => c === sd)),
      )
      .map((grp) => ({
        ...grp,
        selected: grp.members.filter((m) => classes.includes(m.short_description)),
      }));
  }

  getChoiceActivators(choice: MrkrChoice) {
    const group = this.getChoiceGroup(choice);
    if (!group) return undefined;
    return group.activating_short_descriptions;
  }

  getChoice(selection: MrkrChoice | string): MrkrChoice | undefined {
    if (typeof selection !== 'string') {
      return selection;
    }
    if (this.choiceMap[selection]) return this.choiceMap[selection];
    return this.options.choices.find((c) => c.short_description === selection);
  }

  isChoiceActive(choice: MrkrChoice | undefined, altClasses?: string[]): boolean {
    if (choice === undefined) {
      return false;
    }
    const activators = this.getChoiceActivators(choice);
    if (activators && activators.length > 0) {
      const selectedClasses = altClasses || this.getClasses();
      if (!activators.find((a) => selectedClasses.includes(a))) {
        return false;
      }

      // Check that one of the activators is actually active
      return !!activators
        .map((activator) => this.getChoice(activator))
        .find((c) => this.isChoiceActive(c, selectedClasses));
    }

    return true;
  }

  getChoices() {
    if (this.options.choice_groups.length === 0) {
      return this.options.choices;
    }

    return this.options.choices.filter((choice) => this.isChoiceActive(choice));
  }

  getGroupedChoices2display(superChoiceName?: string): GroupedChoices[] {
    const groupNameCount: { [key: string]: number } = {};
    const choiceGroups: ChoiceGroups[] = [];
    let lastGroup = '@@init@@_@@value@@';
    let lastMainChoiceName: string | undefined;

    let rawChoices = this.getChoices().map((c) => ({
      ...c,
      displayName: c.short_description,
      lastWithShared: undefined,
    }));
    // If all subchoices start on the same prefix we want to remove that prefix
    // as if it is a shared stem
    if (
      superChoiceName &&
      !rawChoices.find(
        (c) => c.short_description.substr(0, superChoiceName.length) !== superChoiceName,
      )
    ) {
      rawChoices = rawChoices.map((c) => ({
        ...c,
        displayName: c.displayName.substr(superChoiceName.length),
      }));
    }
    rawChoices.forEach((choice) => {
      let { displayName, detailed_description: detailedDescription } = choice;
      const { group_name: groupName } = choice;
      let keyName: string = groupName || '__undefined__';

      const selected = this.isSelected(choice.short_description);
      detailedDescription =
        detailedDescription || 'There are *no details* specified for this option';

      let addChoice2Grp = true;
      let collapseGroup = false;
      let hasSharedStem = false;
      let prevChoice: string | undefined;
      const grp = this.getChoiceGroup(choice);
      if (grp) {
        const { collapse, max, members, shared_name_stem: shrdStem } = grp;

        const selectedMembers = members.filter((m) => this.isSelected(m.short_description));
        collapseGroup = !!(collapse && max && max <= selectedMembers.length);

        if (lastMainChoiceName) {
          prevChoice = lastMainChoiceName;
          const startLength = lastMainChoiceName.length;
          hasSharedStem = !!(shrdStem && lastGroup !== keyName && lastMainChoiceName);
          if (hasSharedStem) {
            let matchingDesc = displayName.substr(0, startLength);
            // qualifier match
            matchingDesc = matchingDesc.replace(/[_¤]+$/, '');
            hasSharedStem = lastMainChoiceName.substr(0, matchingDesc.length) === matchingDesc;
          }

          if (!selected && collapseGroup) {
            addChoice2Grp = false;
          } else if (selected && collapseGroup) {
            if (hasSharedStem) {
              displayName = displayName.substr(startLength).replace(/^[_¤]+/, '');
              keyName = lastGroup;
            }
            lastMainChoiceName = choice.displayName;
          } else if (hasSharedStem) {
            displayName = `...${displayName.substr(startLength).replace(/^[_¤]+/, '')}`;
            keyName = lastGroup;
          } else {
            lastMainChoiceName = choice.displayName;
          }
        } else {
          lastMainChoiceName = choice.displayName;
        }
      } else {
        lastMainChoiceName = choice.displayName;
      }

      if (addChoice2Grp) {
        if (
          keyName !== lastGroup &&
          (choiceGroups.length === 0 ||
            !choiceGroups[choiceGroups.length - 1].choices.find(
              (c) => grp && grp.activating_short_descriptions.includes(c.short_description),
            ))
        ) {
          if (keyName in groupNameCount) {
            groupNameCount[keyName] += 1;
          } else {
            groupNameCount[keyName] = 0;
          }

          choiceGroups.push({
            name: keyName,
            key: `${keyName}${`_${groupNameCount[keyName]}` || ''}`,
            choices: [],
          });
        }
        choiceGroups[choiceGroups.length - 1].choices.push({
          selected,
          short_description: choice.short_description,
          detailed_description: `${displayName}\n\n${detailedDescription}`,
          display_name: displayName.replace(/^[_¤]+/, '').replace(/^.+¤/, ''),
          lastWithShared: hasSharedStem ? prevChoice : undefined,
        });
        lastGroup = keyName;
      }

      // If the group is collapsed then only the selected choices should be shown
      if (collapseGroup) {
        const choiceGroup = choiceGroups.find(({ name }) => name === keyName);
        if (choiceGroup && grp) {
          choiceGroup.choices = choiceGroup.choices.filter(
            ({ short_description: s }) =>
              !grp.members.find((m) => m.short_description === s) || this.isSelected(s),
          );
        }
      }
    });

    return choiceGroups;
  }

  cachedActiveSuggestedChoices: {
    cache?: SuggestedChoice[];
    touchedChoiceShortDescs?: unknown;
    suggestedClasses?: ClassificationProbability[];
  } = {};

  getActiveSuggestedChoices(): SuggestedChoice[] {
    if (
      this.cachedActiveSuggestedChoices.suggestedClasses === this.suggestedClasses &&
      this.cachedActiveSuggestedChoices.touchedChoiceShortDescs ===
        this.data.touchedChoiceShortDescs &&
      this.cachedActiveSuggestedChoices.cache
    ) {
      return this.cachedActiveSuggestedChoices.cache;
    }

    let ret: SuggestedChoice[] = [];
    if (this.suggestedClasses) {
      ret = this.suggestedClasses
        .filter(({ name }) => !this.data.touchedChoiceShortDescs.includes(name))
        .map(({ name, probability }) => ({
          // eslint-disable-next-line camelcase
          group_name: '',
          short_description: '',
          ...(this.getChoice(name) || {}),
          probability,
        }))
        .filter(({ group_name: gn }) => !this.touchedGroups.has(gn || undefinedGroup));
    }

    this.cachedActiveSuggestedChoices = {
      cache: ret,
      touchedChoiceShortDescs: this.data.touchedChoiceShortDescs,
      suggestedClasses: this.suggestedClasses,
    };
    return ret;
  }

  cachedClasses: CachedClasses = {
    cache: undefined,
    classes: undefined,
    suggestedChoices: undefined,
  };

  getClasses(): string[] {
    const suggestedChoices = this.getActiveSuggestedChoices();
    if (
      this.cachedClasses.classes === this.classes &&
      this.cachedClasses.suggestedChoices === suggestedChoices &&
      this.cachedClasses.cache
    ) {
      return this.cachedClasses.cache;
    }

    const highProbClasses = suggestedChoices
      .filter(({ probability }) => probability > probabilitySelectionThreshold)
      .filter(Boolean)
      .map(({ probability, ...choice }) => choice); // MrkrChoice doesn't have probability

    const mergedClasses: string[] = [...this.classes];

    // We need this hack to avoid evil loop for suggested choices
    const altClasses = [...mergedClasses, ...highProbClasses.map(({ short_description: s }) => s)];
    highProbClasses
      .filter((choice) => this.isChoiceActive(choice, altClasses))
      .forEach(({ short_description: s }) => mergedClasses.push(s));

    const ret = unique(mergedClasses);
    this.cachedClasses = {
      cache: ret,
      classes: this.classes,
      suggestedChoices,
    };
    return ret;
  }

  /**
   * Same as the getClasses but throws errors and removes getChoices
   * that are not activated.
   */
  getSubmissionClasses() {
    const classes = this.getClasses();

    if (classes.length === 0) {
      if (!this.options.required) {
        return [];
      }
      // This should not be allowed within the app, isDone should prevent this
      // scenario
      throw new Error(`No class data to retrieve for ${this.short_description}`);
    }

    return classes
      .map<MrkrChoice | undefined>((c) => this.getChoice(c))
      .filter((c): c is MrkrChoice => !!c)
      .filter((c) => this.isChoiceActive(c))
      .map<string>((c) => c.short_description);
  }

  getSubmissionObject(): ClassificationSubmission {
    const ret = {
      ...super.getBaseSubmissionObject(),
      classes: this.getSubmissionClasses(),
    };
    return ret;
  }

  findAndSetSuggested(suggestedLabels: SuggestedLabels[]): Classification {
    const typeSuggestions = this.prFindTypeLabels(suggestedLabels);
    if (!typeSuggestions) return this;

    const { classifications: suggestions } = typeSuggestions;
    if (!suggestions) return this;

    const classSuggestion = suggestions.find(
      ({ short_description: sd }) => sd === this.short_description,
    );
    if (!classSuggestion) return this;

    return this.setSuggested(classSuggestion);
  }

  setSuggested(vals: SuggestedClassification): Classification {
    super.setSuggested(vals);
    this.data = produce<CoreData>(this.data, (draft) => {
      let softmaxedClasses = [...vals.classes];
      // This section makes sure that the classes summed probabilities
      // aren't larger than the allowed number of classes, i.e. if
      // there are three choices where you can choose two then the sum of
      // the probabilities should never allow for more than 2 classes selected
      this.data.options.choice_groups.forEach((grp) => {
        const { max } = grp;
        if (!max) return;

        const choices = this.data.options.choices
          .filter(({ group_name: gn }) => grp.name === gn)
          .map((c) => c.short_description);

        const grpSuggestions = softmaxedClasses.filter(
          ({ name }, index, self) =>
            choices.includes(name) &&
            // In case a duplicate occurrs in the sent labels we want to filter
            // so that only one suggestion is available and here we pick the first
            self.findIndex((elmnt) => elmnt.name === name) === index,
        );
        const sum = grpSuggestions
          .map(({ probability }) => probability)
          .reduce((prev, probability) => prev + probability, 0);

        // Here we divide by the maximum allowed choices within the group
        // but we don't want to divide with max due to floating point issues
        // as we sometimes have extremely rare classes that by their
        // rarity should have a prediction close to 0
        const roundToTwoDecimals = (number: number) => Math.round(number * 100) / 100;
        if (roundToTwoDecimals(sum) > roundToTwoDecimals(max)) {
          softmaxedClasses = [
            ...softmaxedClasses.filter(({ name }) => !choices.includes(name)),
            ...grpSuggestions.map(({ name, probability }) => ({
              name,
              probability: probability / (sum / max),
            })),
          ];
        }
      });

      draft.suggestedClasses = softmaxedClasses;
    });

    // Solves the immutability issue where UI fails to update
    return this.clone();
  }

  isDone() {
    if (!super.isDone()) return false;
    const classes = this.getClasses();
    if (classes.length === 0) {
      return false;
    }

    let done = true;
    const { min, max, choices } = this.options;
    if (min || max) {
      done = done && (min || 1) <= classes.length;
      done = done && (max || choices.length) >= classes.length;
    } else {
      // We match at least 1
      done = classes.length >= 1;
    }

    const unfinishedGroups = this.getActiveGroups().filter(
      ({ min: m, selected }) => m && m > selected.length,
    );
    if (unfinishedGroups.length > 0) {
      done = false;
    }

    return done;
  }

  isSelected(className: string, strictlyClicked = false) {
    if (strictlyClicked) {
      return this.classes.findIndex((c) => c === className) >= 0;
    }
    return this.getClasses().findIndex((c) => c === className) >= 0;
  }

  hasBeenSuggested(shortDescription: string) {
    if (this.isSelected(shortDescription, true)) {
      return false;
    }

    if (
      this.getActiveSuggestedChoices().findIndex(
        ({ short_description: s }) => s === shortDescription,
      ) >= 0
    ) {
      return true;
    }

    return false;
  }

  isSuggested(shortDescription: string) {
    if (this.isSelected(shortDescription, true)) {
      return false;
    }

    return !!this.getClasses().includes(shortDescription);
  }

  // We need to know if a group has been modified so that
  // we can figure out if the suggested labels for that group should be active
  touchedGroups: Set<string | symbol> = new Set();

  toggleGroup(choice: MrkrChoice) {
    const { group_name: groupName } = choice;
    if (!groupName) {
      if (this.options.max === 1) {
        this.touchedGroups.add(undefinedGroup);
      }
      return;
    }

    this.data = produce<CoreData>(this.data, (draft) => {
      if (!(groupName in draft.groupSelections)) {
        draft.groupSelections[groupName] = [];
      }

      if (this.isSelected(choice.short_description)) {
        draft.groupSelections[groupName] = draft.groupSelections[groupName].filter(
          (c) => c !== choice.short_description,
        );
      } else {
        draft.groupSelections[groupName].push(choice.short_description);
      }
    });

    const choiceGroup = this.options.choice_groups.find((cg) => cg.name === groupName);
    if (!choiceGroup || !choiceGroup.max) return;

    const { max } = choiceGroup;
    if (max === 1) {
      this.touchedGroups.add(groupName);
    }
    if (this.groupSelections[groupName].length > max) {
      const removedClass = this.groupSelections[groupName].shift();
      this.data = produce<CoreData>(this.data, (draft) => {
        draft.classes = draft.classes.filter((c) => c !== removedClass);
      });
    }
  }

  toggleClass(option: string, recursive = false) {
    // Make sure that the current choice is actually activated
    if (
      !this.getChoices()
        .map((c) => c.short_description)
        .includes(option)
    ) {
      return this;
    }

    // eslint-disable-next-line @typescript-eslint/no-this-alias
    let that: Classification = this;
    if (!recursive) {
      that = this.clone();
    }

    const isSelected = that.isSelected(option);
    that.data = produce<CoreData>(that.data, (draft) => {
      if (!draft.touchedChoiceShortDescs.includes(option)) {
        draft.touchedChoiceShortDescs.push(option);
      }

      const choice = that.getChoice(option);
      if (!choice)
        throw new Error(`The ${option} doesn't exist among ${draft.options.choices.join(', ')}`);
      that.toggleGroup(choice);

      if (!isSelected) {
        draft.classes.push(option);
        const {
          options: { max },
        } = that;
        if (max) {
          if (draft.classes.length > max) {
            draft.classes.shift();
          }
        }
      } else {
        const dependentGroups = that.getGroups(option);
        let members2remove = [option];
        dependentGroups.forEach((grp) => {
          const options = grp.members.map((c) => c.short_description);
          members2remove = members2remove.concat(options);
        });
        draft.classes = draft.classes.filter((c) => !members2remove.includes(c));
      }
    });

    // Solves the immutability issue where UI fails to update
    return that;
  }
}
