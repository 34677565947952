import gql from 'graphql-tag';
import { ThemeTypes } from '../../themes';

export interface WorkerSettings {
  display: {
    no_images_to_view: number;
    force_no_images_to_view: boolean;
    greedy_scaling: boolean;
    theme_name: ThemeTypes | null;
  };
}

export interface WorkerSettingsDisplayInput {
  no_images_to_view?: number | undefined;
  force_no_images_to_view?: boolean | undefined;
  greedy_scaling?: boolean | undefined;
  theme_name?: ThemeTypes | undefined;
}

export interface WorkerSettingsInput {
  display: WorkerSettingsDisplayInput;
}

export interface WorkerFrgmntType {
  id: string;
  service: string | undefined;
  income: number;
  settings: WorkerSettings;
  created_at: string;
  updated_at: string | undefined;
}

// eslint-disable-next-line import/prefer-default-export
export const workerFrgmnt = gql`
  fragment workerFrgmnt on Worker {
    id
    service
    income
    settings {
      display {
        no_images_to_view
        force_no_images_to_view
        greedy_scaling
        theme_name
      }
    }
  }
`;
