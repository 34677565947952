import * as React from 'react';
import styled from 'styled-components';
import { Alert } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFrown } from '@fortawesome/pro-solid-svg-icons';

import { Container } from './containers/StartPage/Layout';
import hero from './img/hero_bg.jpg';
import { CenterBox } from './components';

import { env } from './config';

const ErrorHeaderBox = styled.div`
  clear: right;
  display: flex;
  flex-direction: row;
  align-items: center;
  & > i {
    font-size: 2em;
    display: block;
  }
  & > h1 {
    margin-left: 0.2em;
    font-size: 1.5em;
    font-weight: 900;
    flex-grow: 1;
    padding-left: 5px;
  }
  padding-bottom: 5px;
  margin-bottom: 5px;
  border-bottom: 2px solid #555;
`;

const AppError = () => (
  <Container id="startpage-container" style={{ backgroundImage: `url(${hero})` }}>
    <CenterBox>
      <Alert color="danger">
        <ErrorHeaderBox>
          <FontAwesomeIcon icon={faFrown} />
          <h1>Sorry, connection failure</h1>
        </ErrorHeaderBox>
        <p>
          Oops... we failed to connect with the data-server (back-end). This is most likely some
          temporary problem at the server or you are using one of the web-browsers:
        </p>
        <ul>
          <li>Internet Explorer</li>
          <li>Safari</li>
        </ul>
        <p>
          Contact admin for help. Tell them that the server located at <i>{env.GRAPHQL_URI}</i>{' '}
          doesn&#39;t respond. Please take the time to report to admin at support@deep-med.com.
        </p>
        <p>
          Thank you for understanding and helping out!
          <br />
          <i style={{ marginLeft: '2em' }}>Support at DeepMed AB</i>
        </p>
      </Alert>
    </CenterBox>
  </Container>
);

export default AppError;
