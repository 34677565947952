import gql from 'graphql-tag';

// eslint-disable-next-line import/prefer-default-export
export const labelsFrgmnt = gql`
  fragment labelsFrgmnt on Labels {
    task_type {
      id
      name
    }
    boxes {
      short_description
      center {
        x
        y
      }
      height
      width
      angle
      image {
        id
        original_name
      }
    }
    circles {
      short_description
      center {
        x
        y
      }
      radiusPoint {
        x
        y
      }
      image {
        id
        original_name
      }
    }
    connectedLines {
      short_description
      c1 {
        x
        y
      }
      c2 {
        x
        y
      }
      c3 {
        x
        y
      }
      image {
        id
        original_name
      }
    }
    independentLines {
      short_description
      line1 {
        c1 {
          x
          y
        }
        c2 {
          x
          y
        }
      }
      line2 {
        c1 {
          x
          y
        }
        c2 {
          x
          y
        }
      }
      image {
        id
        original_name
      }
    }
    lines {
      short_description
      c1 {
        x
        y
      }
      c2 {
        x
        y
      }
      image {
        id
        original_name
      }
    }
    pens {
      short_description
      coordinates {
        x
        y
      }
      image {
        id
        original_name
      }
    }
  }
`;

interface XYpos {
  x: number;
  y: number;
}

interface ImgType {
  id: string;
  original_name: string;
}

export interface BaseLabel {
  short_description: string;
}

export interface ImgLabel extends BaseLabel {
  image: ImgType;
}

export interface ClassificationProbability {
  name: string;
  probability: number;
}

export interface LabelClassification extends BaseLabel {
  [key: string]: ClassificationProbability[] | string | undefined;
  classes: ClassificationProbability[];
}

export interface LabelBox extends ImgLabel {
  [key: string]: XYpos | string | number | ImgType | undefined;
  center: XYpos;
  height: number;
  width: number;
  angle: number;
}

export interface LabelCircle extends ImgLabel {
  [key: string]: XYpos | string | ImgType | undefined;
  center: XYpos;
  radiusPoint: XYpos;
}

export interface LabelConnectedLines extends ImgLabel {
  [key: string]: XYpos | string | ImgType | undefined;
  c1: XYpos;
  c2: XYpos;
  c3: XYpos;
}

export interface LabelIndependentLines extends ImgLabel {
  [key: string]: { c1: XYpos; c2: XYpos } | number | string | ImgType | undefined;
  line1: { c1: XYpos; c2: XYpos };
  line2: { c1: XYpos; c2: XYpos };
}

export interface LabelLine extends ImgLabel {
  [key: string]: XYpos | number | string | ImgType | undefined;
  c1: XYpos;
  c2: XYpos;
}
export interface LabelPen extends ImgLabel {
  coordinates: XYpos[];
}

export interface LabelCoordinate extends ImgLabel, XYpos {}

interface LabelLabelType {
  id: string;
  name: string;
}

export type AnyLabel =
  | LabelBox
  | LabelCircle
  | LabelClassification
  | LabelConnectedLines
  | LabelCoordinate
  | LabelIndependentLines
  | LabelLine
  | LabelPen;

export interface LabelFrgmntType {
  [key: string]: LabelLabelType | string | AnyLabel[] | undefined;
  task_type: LabelLabelType;
  report: string | undefined;
  boxes: LabelBox[];
  classifications: LabelClassification[];
  circles: LabelCircle[];
  connectedLines: LabelConnectedLines[];
  coordinates: LabelCoordinate[];
  independentLines: LabelIndependentLines[];
  lines: LabelLine[];
  pens: LabelPen[];
}
