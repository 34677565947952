import _env, { EnvironmentTypes } from './env';

const { NODE_ENV, REACT_APP_URI } = process.env;
let environment: EnvironmentTypes = (NODE_ENV as EnvironmentTypes) || 'development';
if (REACT_APP_URI === 'api') {
  environment = 'production';
}

// Yes - very complicated deduction of the URI for the central backend
let uri = 'https://api.ai-xray.com';
if (REACT_APP_URI) {
  if (REACT_APP_URI.match(/^https:\/\//)) {
    uri = REACT_APP_URI;
    // The SSL forces production
    environment = 'production';
  } else if (environment === 'development' && REACT_APP_URI !== 'api') {
    uri = 'http://localhost:10010';
  }
} else if (environment === 'development') {
  uri = 'http://localhost:10010';
}
uri = uri.replace(/^https?:\/\//, '');
uri = uri.replace(/\/+$/, '');
const ret = _env[environment];
ret.API_URL = ret.API_URL.replace(/%replace%/, uri);
ret.GRAPHQL_URI = ret.GRAPHQL_URI.replace(/%replace%/, uri);
ret.SUBSCRIPTION_URI = ret.SUBSCRIPTION_URI.replace(/%replace%/, uri);

if (environment !== 'test') {
  // eslint-disable-next-line no-console
  console.log('Environment', ret);
}
export const env = ret;

export const host = uri.replace(/(:[0-9]+)\/.*/, '');

export const probabilitySelectionThreshold = 0.6;

export const entrypoint =
  process.env.REACT_APP_ENTRYPOINT || (process.env.REACT_APP_DEMO && '/demo') || '/label';
