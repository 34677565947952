import gql from 'graphql-tag';
import { Roles } from '../../types/schema.types';
import { WorkerFrgmntType } from './worker';
import { CurrenTaskFrgmntType } from './currentTask';

export interface UserFrgmntType {
  [key: string]: string | number | Roles[] | boolean | unknown;
  id: string;
  username: string;
  name: string | undefined;
  first_name: string | undefined;
  middle_name: string | undefined;
  last_name: string | undefined;
  email: string | undefined;
  banned: boolean;
  roles: Roles[];
  created_at: string;
  updated_at: string | undefined;
}

export interface WorkerType extends WorkerFrgmntType {
  __typename: string;
  statistics: {
    total: number;
  };
  current_task: CurrenTaskFrgmntType | undefined;
}

export interface CurrentUserType extends UserFrgmntType {
  [key: string]: string | number | Roles[] | boolean | WorkerType | undefined;
  __typename: string;
  worker: WorkerType;
}
// eslint-disable-next-line import/prefer-default-export
export const userFrgmnt = gql`
  fragment userFrgmnt on User {
    id
    username
    name
    first_name
    middle_name
    last_name
    email
    banned
    roles
    updated_at
    created_at
  }
`;
