import { produce } from 'immer';
import ImgMrkr, { Props as ImgProps, CoreData as ImgCoreData, SubmissionObject } from './ImgMrkr';
import { samePoint, purePosition, getMappedCoordsArray } from './helpers';
import { XYpos, XYposWithType } from './types/XYpos';
import { SuggestedPen } from '../../types';
import { DrawArguments, GetPointsArguments } from './types/general';

interface PenSubmission extends SubmissionObject {
  coordinates: XYpos[];
  short_description: string;
  image: string;
}

export interface CoreData extends ImgCoreData {
  coordinates: XYpos[];
  done: boolean;
  type: 'Pen';
}

export default class Pen extends ImgMrkr<ImgProps, CoreData> {
  constructor(props: ImgProps) {
    super(props, 'Pen');
    if (!(props instanceof Pen)) {
      this.data = produce<CoreData>(this.data, (draft) => {
        draft.coordinates = draft.coordinates || [];
        if (draft.done === undefined) {
          draft.done = false;
        }
      });
    }
  }

  get coordinates() {
    return this.data.coordinates;
  }

  get done() {
    return this.data.done;
  }

  // @ts-ignore - clone doesn't handle inheritance well
  clone(): Pen {
    const clone = super.clone();
    clone.data = produce<CoreData>(this.data, (draft) => {
      draft.coordinates = [...draft.coordinates];
    });
    return clone;
  }

  getSubmissionObject(): PenSubmission | null {
    if (this.requireDone('pen') === null) {
      return null;
    }

    const coordinates = this.getPoints({ useMovingPoint: false }).filter((c): c is XYpos => !!c);
    if (!coordinates || coordinates.length === 0) {
      throw new Error('The coordinates must be set before preparing submission object');
    }
    return {
      ...super.getImgSubmissionObject(),
      coordinates,
    };
  }

  setSuggested(vals: SuggestedPen): Pen {
    super.setSuggested(vals);
    vals.coordinates
      .filter((c): c is XYposWithType => !!c)
      .forEach((point, index) => this.setPoint(purePosition(point), index, vals.image.id));
    this.data = produce<CoreData>(this.data, (draft) => {
      draft.suggested = true;
    });

    return this.clone();
  }

  isDone(): boolean {
    if (!super.isDone()) return false;

    if (this.coordinates.length > 0 && this.done) return true;

    return false;
  }

  setDone(status = true) {
    this.unSetMovingPoint();
    this.data = produce<CoreData>(this.data, (draft) => {
      draft.done = status;
    });
  }

  getIndex(point: XYpos): number {
    for (let i = 0; i < this.coordinates.length; i += 1) {
      if (samePoint(point, this.coordinates[i])) {
        return i;
      }
    }

    return super.getIndex(point);
  }

  /**
   * Set the position points for the line. If index is left empty
   * it will choose the last position.
   */
  setPoint(point: XYpos, index: number | undefined, imgId: string): Pen {
    if (!this.image) this.setImage(imgId);
    if (this.image !== imgId) return this;
    this.data = produce<CoreData>(this.data, (draft) => {
      draft.suggested = false;

      const noCoords = draft.coordinates.length;
      if (index === undefined) {
        // Don't save the same position as the last element
        if (noCoords > 0) {
          const last = draft.coordinates[noCoords - 1];
          if (last.x === point.x && last.y === point.y) return;
        }
        draft.coordinates.push(point);
      } else {
        const elmnt2set: number = Math.round(index);

        if (elmnt2set < 0 || elmnt2set > noCoords) {
          throw new Error(
            `Trying to set coordinates ${index} outside the allowed span 0 to ${noCoords}`,
          );
        } else {
          draft.coordinates[elmnt2set] = point;
        }
      }
    });

    return this.clone();
  }

  draw({ ctxt, width, height, scale, strokeStyle, fillStyle, ...otherArgs }: DrawArguments) {
    if (this.coordinates.length === 0) return;

    const relativePoints = this.getPoints({ useMovingPoint: true });
    const points = getMappedCoordsArray(relativePoints, width, height).filter(
      (p): p is XYpos => !!p,
    );
    strokeStyle = this.getStrokeStyle(strokeStyle); // eslint-disable-line
    ctxt.strokeStyle = strokeStyle; // eslint-disable-line
    ctxt.fillStyle = fillStyle || strokeStyle; // eslint-disable-line

    // Draw the actual line
    ctxt.beginPath();
    points.forEach(({ x, y }, index) => {
      if (index === 0) {
        ctxt.moveTo(Math.round(x), Math.round(y));
      } else {
        ctxt.lineTo(Math.round(x), Math.round(y));
      }
    });
    ctxt.stroke();
    ctxt.closePath();

    super.draw({
      ctxt,
      width,
      height,
      scale,
      fillStyle,
      strokeStyle,
      ...otherArgs,
    });
  }

  getPoints({ useMovingPoint = false }: GetPointsArguments = {}): (XYpos | undefined)[] {
    const points = [...this.coordinates]; // copy to avoid by ref. manipulation
    if (useMovingPoint && this.movingPoint && (!this.isDone() || this.movingIndex)) {
      if (this.movingIndex !== undefined && this.movingIndex !== null) {
        if (this.movingIndex < 0 || this.movingIndex > points.length) {
          throw new Error('Moving point outside allowed index');
        }
        points[this.movingIndex] = this.movingPoint;
      } else {
        points.push(this.movingPoint);
      }
    }
    return points;
  }
}
