import gql from 'graphql-tag';

// eslint-disable-next-line import/prefer-default-export
export const patientFrgmnt = gql`
  fragment patientFrgmnt on Patient {
    patient_id
    name
  }
`;

export interface PatientFrgmntType {
  patient_id: string;
  name: string;
}
