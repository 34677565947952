import { ReactNode } from 'react';
import { Action as ReduxAction } from 'redux';

export const SET_HEADER = 'SET_HEADER';
export const SET_ANONYMIZE = 'SET_ANONYMIZE';
export const SET_BURGER_MENU_OPEN = 'SET_BURGER_MENU_OPEN';

export interface SetHeaderAction extends ReduxAction {
  type: 'SET_HEADER';
  txt: HeaderType;
}

export interface SetAnonymizeAction extends ReduxAction {
  type: 'SET_ANONYMIZE';
  anonymize: boolean;
}

export interface SetBurgerOpenAction extends ReduxAction {
  type: 'SET_BURGER_MENU_OPEN';
  open: boolean;
}

export type HeaderActions = SetHeaderAction | SetAnonymizeAction | SetBurgerOpenAction;

export type HeaderType = string | ReactNode;
