import { produce } from 'immer';
import {
  SET_REPORT_SIMPLE,
  TOGGLE_REPORT_SIMPLE,
  SetReportSimpleAction,
  ToggleReportSimpleAction,
} from '../actions/types';
import { ReportState } from './types';

export default (
  state: ReportState = { simple: false },
  args: SetReportSimpleAction | ToggleReportSimpleAction,
) =>
  produce<ReportState>(state, (draft) => {
    switch (args.type) {
      case SET_REPORT_SIMPLE: {
        const { simple } = args;

        draft.simple = simple;
        break;
      }
      case TOGGLE_REPORT_SIMPLE: {
        draft.simple = !draft.simple;
        break;
      }
      default:
    }
  });
