import { AnyTool } from '../../utils/Markers/types';
import { LabelsKeys } from '../../utils/Markers/types/general';
import { getToolLabelName } from '../../utils/Markers';

const requireToolName = (tool: AnyTool): LabelsKeys => {
  const toolName = getToolLabelName(tool);
  if (!toolName) throw new Error(`Could not identify the tool ${tool.constructor.name}`);
  return toolName;
};

export default requireToolName;
