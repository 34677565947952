import * as React from 'react';
import { Route, Redirect, RouteComponentProps, RouteProps } from 'react-router';
import { QueryRsltsType } from '../gql/useCurrentUser';
import { Roles } from '../types';

interface Props<T extends object = {}> extends RouteProps {
  children: (props: RouteComponentProps<T>) => React.ReactNode;
  currentUserQuery: QueryRsltsType;
}

export default class WorkerRoute extends React.PureComponent<Props> {
  static role: Roles[] = ['user', 'demo'];

  getUser() {
    const {
      currentUserQuery: { data },
    } = this.props;

    if (!data || !data.user || !data.user.current) return false;

    return data.user.current;
  }

  isAuthenticated() {
    const {
      currentUserQuery: { loading },
    } = this.props;

    if (loading) {
      // Assume true until we have the result from the
      // current user query
      return true;
    }

    const user = this.getUser();
    return !!user;
  }

  isAuthorized() {
    const user = this.getUser();
    if (!user) throw new Error('Failed to check authentication before authorization');

    return WorkerRoute.role
      .map((v) => user.roles.includes(v))
      .reduce((prev, v) => v || prev, false);
  }

  renderRoute = (props: RouteComponentProps) => {
    if (!this.isAuthenticated()) {
      return (
        <Redirect
          to={{
            pathname: `${process.env.PUBLIC_URL || ''}/login`,
          }}
        />
      );
    }

    if (!this.isAuthorized()) {
      return (
        <Redirect
          to={{
            pathname: `${process.env.PUBLIC_URL || ''}/unauthorized`,
          }}
        />
      );
    }

    const { children: TheComponent } = this.props;
    return TheComponent(props);
  };

  render() {
    const {
      children: TheComponent,
      currentUserQuery: { loading },
      ...rest
    } = this.props;
    if (loading) {
      return <div />;
    }

    // eslint-disable-next-line react/jsx-props-no-spreading
    return <Route {...rest} render={this.renderRoute} />;
  }
}
