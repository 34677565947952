import { produce } from 'immer';
import { ADD_WORK, CLEAR_CAMPAIGN, ARCHIVED_WORK, WorkActions } from '../actions/types';
import { getMissingErrorMsg } from './helpers';
import { WorkState, CampaignWorkType } from './types';

const initCampaignData = (): CampaignWorkType => ({
  tasks2do: [],
  doneTasks: [],
  name: '__unset__',
});

const initialState: WorkState = { campaigns: {} };

export default (state: WorkState = initialState, action: WorkActions) =>
  produce<WorkState>(state, (draft) => {
    switch (action.type) {
      case ADD_WORK: {
        const { campaignId, name, tasks2do } = action;
        if (!campaignId || !tasks2do || !name) {
          const msg = getMissingErrorMsg({ campaignId, name, tasks2do }, 'Invalid add work params');
          throw new Error(msg);
        }

        const campaign = draft.campaigns[campaignId];
        if (campaign) {
          const existingIds = campaign.tasks2do.map((t) => t.id);
          const doneIds = campaign.doneTasks.map((t) => t.id);
          const tasks2add = tasks2do.filter(
            (t) => !existingIds.includes(t.id) && !doneIds.includes(t.id),
          );
          if (tasks2add.length > 0) {
            campaign.tasks2do = [...campaign.tasks2do, ...tasks2add];
          }
        } else {
          draft.campaigns[campaignId] = {
            ...initCampaignData(),
            name,
            tasks2do,
          };
        }
        break;
      }

      case ARCHIVED_WORK: {
        const { campaignId, taskId } = action;
        if (!campaignId || !taskId) {
          const msg = getMissingErrorMsg({ campaignId, taskId }, 'Invalid finished work params');
          throw new Error(msg);
        }

        const campaign = draft.campaigns[campaignId];
        if (!campaign) throw new Error('No campaign registered');

        const taskIdx = campaign.tasks2do.findIndex((t) => t.id === taskId);
        if (taskIdx === -1) {
          const finishedIdx = campaign.doneTasks.findIndex((t) => t.id === taskId);
          if (finishedIdx === -1) {
            throw new Error("The task that is marked as finished isn't present among the tasks!?");
          }
        } else {
          const task = campaign.tasks2do[taskIdx];
          campaign.doneTasks.push(task);
          campaign.tasks2do.splice(taskIdx, 1);
        }
        break;
      }

      case CLEAR_CAMPAIGN: {
        const { campaignId } = action;
        if (!campaignId) throw new Error('Campaign id is required');

        if (draft.campaigns[campaignId]) {
          delete draft.campaigns[campaignId];
        }
        break;
      }

      default:
    }
  });
