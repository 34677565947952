import { produce } from 'immer';
import ImgMrkr, { Props as ImgProps, CoreData as ImgCoreData } from './ImgMrkr';
import { samePoint, updatePoint, pointDistance } from './helpers';
import { XYpos } from './types/XYpos';
import { SuggestedBox } from '../../gql/fragments/task2label/suggestedLabels';
import { DrawArguments, GetPointsArguments } from './types/general';

interface BoxSubmission {
  center: XYpos;
  width: number;
  height: number;
  angle: number;
  short_description: string;
  image: string;
}

export interface CoreData extends ImgCoreData {
  c1: XYpos | undefined;
  c2: XYpos | undefined;
  center: XYpos | undefined;
  width: number | undefined;
  height: number | undefined;
  angle: number | undefined;
  type: 'Box';
}

export interface Props extends ImgProps {
  c1?: XYpos;
  c2?: XYpos;
}

const getMetaValues = (p1: XYpos, p2: XYpos) => {
  const { dx, dy } = pointDistance({ p1, p2 });
  return {
    angle: 0,
    width: Math.abs(dx),
    height: Math.abs(dy),
    center: {
      x: p1.x - dx / 2,
      y: p1.y - dy / 2,
    },
  };
};

export default class Box extends ImgMrkr<Props, CoreData> {
  constructor(props: Props) {
    super(props, 'Box');
  }

  get c1() {
    return this.data.c1;
  }

  get c2() {
    return this.data.c2;
  }

  get center() {
    return this.data.center;
  }

  get width() {
    return this.data.width;
  }

  get height() {
    return this.data.height;
  }

  get angle() {
    return this.data.angle;
  }

  getSubmissionObject(): BoxSubmission {
    if (
      !this.center ||
      typeof this.width !== 'number' ||
      typeof this.height !== 'number' ||
      typeof this.angle !== 'number'
    )
      throw new Error('The elements are for some reason not fixed');

    return {
      ...super.getImgSubmissionObject(),
      ...{
        center: this.center,
        width: this.width,
        height: this.height,
        angle: this.angle,
      },
    };
  }

  setSuggested(vals: SuggestedBox): Box {
    super.setSuggested(vals);
    const { width, height, center, angle, image } = vals;
    if (angle !== 0) throw new Error('Angle not yet implemented');

    const c1 = {
      x: Math.round(center.x - width / 2),
      y: Math.round(center.y - height / 2),
    };
    const c2 = {
      x: Math.round(center.x + width / 2),
      y: Math.round(center.y + height / 2),
    };
    this.setPoint(c1, 0, image.id);
    this.setPoint(c2, 1, image.id);

    return this.clone();
  }

  getIndex(point: XYpos): number {
    // If all points match then we shouldn't choose the
    // center as this won't allow the user to resize the box
    // from a single dot since the box will just be moved around
    const points = this.getPoints({ useMovingPoint: false });
    if (
      points
        .filter((p): p is XYpos => !!p)
        .filter((p) => pointDistance({ p1: point, p2: p }).distance === 0).length === 3
    ) {
      return 1;
    }

    const [center, c1, c2] = points;
    if (samePoint(point, center)) {
      return 0;
    }

    if (samePoint(point, c1)) {
      return 1;
    }

    if (samePoint(point, c2)) {
      return 2;
    }

    return super.getIndex(point);
  }

  isDone(): boolean {
    if (!super.isDone()) return false;

    if (!this.c1 || !this.c2) return false;

    return true;
  }

  /**
   * Set the center and the radius for the line. If index is empty
   * it will first choose center and if this is set it will add the
   * radius/radiusPoint
   */
  setPoint(point: XYpos, index: number | undefined, imgId: string): Box {
    if (!this.image) this.setImage(imgId);
    if (this.image !== imgId) return this;
    this.data = produce<CoreData>(this.data, (draft) => {
      draft.suggested = false;

      // TODO: handle box angle
      let elmnt2set: 'center' | 'c1' | 'c2';
      if (!this.isDone()) {
        if (!this.c1) {
          elmnt2set = 'c1';
        } else {
          elmnt2set = 'c2';
        }
      } else {
        switch (index) {
          case 0:
            elmnt2set = 'center';
            break;
          case 1:
            elmnt2set = 'c1';
            break;
          case 2:
            elmnt2set = 'c2';
            break;
          default:
            throw new Error(`The index must be 0 or 2 - you provided ${index}`);
        }
      }

      switch (elmnt2set) {
        case 'center': {
          if (!draft.center) throw new Error('Trying to set center without having a box');
          const { dx, dy } = pointDistance({ p1: draft.center, p2: point });
          draft.center = point;
          if (draft.c1) {
            draft.c1 = updatePoint(draft.c1, dx, dy);
          }
          if (draft.c2) {
            draft.c2 = updatePoint(draft.c2, dx, dy);
          }
          break;
        }
        case 'c1':
          draft.c1 = point;
          break;
        case 'c2': {
          const { c1 } = draft;
          if (!c1) throw new Error('The first point should be set');
          draft.c2 = point;
          const { angle, width, height, center } = getMetaValues(c1, draft.c2);
          draft.angle = angle;
          draft.width = width;
          draft.height = height;
          draft.center = center;
          break;
        }
        default:
          break;
      }
    });

    return this.clone();
  }

  draw({ ctxt, width, height, scale, strokeStyle, fillStyle, ...otherArgs }: DrawArguments) {
    const [center, c1, c2] = this.getPoints({ useMovingPoint: true });
    if (!c1) return;
    strokeStyle = this.getStrokeStyle(strokeStyle); // eslint-disable-line
    ctxt.shadowColor = '#555555'; // eslint-disable-line
    ctxt.shadowBlur = 2; // eslint-disable-line

    if (c2 && center) {
      const { width: rWidth, height: rHeight } = getMetaValues(c1, c2);
      const upperLeft = {
        x: (center.x - rWidth / 2) * width,
        y: (center.y - rHeight / 2) * height,
      };

      ctxt.beginPath();
      ctxt.rect(
        Math.round(upperLeft.x),
        Math.round(upperLeft.y),
        Math.round(rWidth * width),
        Math.round(rHeight * height),
      );

      ctxt.strokeStyle = strokeStyle; // eslint-disable-line
      if (fillStyle) {
        ctxt.fillStyle = fillStyle; // eslint-disable-line
      }
      ctxt.stroke();
      ctxt.closePath();
    }
    ctxt.shadowColor = ''; // eslint-disable-line
    ctxt.shadowBlur = 0; // eslint-disable-line

    super.draw({
      ctxt,
      width,
      height,
      scale,
      fillStyle,
      strokeStyle,
      ...otherArgs,
    });
  }

  pointC1(useMovingPoint: boolean): XYpos | undefined {
    if (useMovingPoint && this.movingPoint && this.movingIndex === 1) {
      return this.movingPoint;
    }
    return this.c1;
  }

  pointC2(useMovingPoint: boolean): XYpos | undefined {
    if (useMovingPoint && this.movingPoint) {
      if (!this.c2 && this.movingIndex === undefined) {
        return this.movingPoint;
      }

      if (this.movingIndex === 2) {
        return this.movingPoint;
      }
    }

    return this.c2;
  }

  pointCenter(useMovingPoint: boolean): XYpos | undefined {
    const c1 = this.pointC1(useMovingPoint);
    const c2 = this.pointC2(useMovingPoint);
    if (c1 && c2) {
      return getMetaValues(c1, c2).center;
    }

    return this.center;
  }

  getPoints({ useMovingPoint = false }: GetPointsArguments = {}): [
    XYpos | undefined,
    XYpos | undefined,
    XYpos | undefined,
  ] {
    let c1 = this.pointC1(useMovingPoint);
    let c2 = this.pointC2(useMovingPoint);
    let center = this.pointCenter(useMovingPoint);

    // Adjust both the c1 & c2 if the user is dragging the center
    if (useMovingPoint && this.movingPoint && this.movingIndex === 0 && center) {
      const { dx, dy } = pointDistance({ p1: center, p2: this.movingPoint });
      if (c1) c1 = updatePoint(c1, dx, dy);
      if (c2) c2 = updatePoint(c2, dx, dy);
      center = this.movingPoint;
    }

    return [center, c1, c2];
  }

  countUnsetPoints(): number {
    const [center, c1, c2] = this.getPoints(); // eslint-disable-line
    let noCorePoints = 2;
    if (c1) noCorePoints -= 1;
    if (c2) noCorePoints -= 1;
    return noCorePoints;
  }
}
