/* eslint-disable @typescript-eslint/indent */
import gql from 'graphql-tag';

// eslint-disable-next-line import/prefer-default-export
export const conditionsFrgmnt = gql`
  fragment conditionsFrgmnt on ConditionalActivation {
    criteria {
      task_type
      short_description
      comparison
      value4criteria {
        number
        string
        array_string
      }
      value_selector
    }
    target {
      task_type
      short_description
    }
  }
`;

export interface Target {
  task_type: string;
  short_description: string | undefined;
}

export type ComparisonsAvailable =
  | 'TRUE'
  | 'FALSE'
  | 'EQUAL'
  | 'ONE_OF' // eslint-disable-line no-multi-spaces
  | 'NOT_EQUAL'
  | 'NOT_ONE_OF';

type Value4Criteria =
  | {
      string: void;
      number: number;
      array_string: void;
    }
  | {
      string: string;
      number: void;
      array_string: void;
    }
  | {
      string: void;
      number: void;
      array_string: string[];
    };

export interface CoreCriterium {
  task_type: string;
  short_description: string;
  comparison: ComparisonsAvailable;
  value4criteria: Value4Criteria;
  value_selector: string[];
}

export interface ConditionsFrgmntType {
  criteria: CoreCriterium[];
  target: Target;
}
