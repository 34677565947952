import gql from 'graphql-tag';

// eslint-disable-next-line import/prefer-default-export
export const suggestedLabelsFrgmnt = gql`
  fragment suggestedLabelsFrgmnt on SuggestedLabels {
    task_type {
      id
      name
    }
    report
    classifications {
      short_description
      classes {
        name
        probability
      }
    }
    boxes {
      short_description
      center {
        x
        y
      }
      height
      width
      angle
      image {
        id
        original_name
      }
    }
    circles {
      short_description
      center {
        x
        y
      }
      radiusPoint {
        x
        y
      }
      image {
        id
        original_name
      }
    }
    connectedLines {
      short_description
      c1 {
        x
        y
      }
      c2 {
        x
        y
      }
      c3 {
        x
        y
      }
      image {
        id
        original_name
      }
    }
    independentLines {
      short_description
      line1 {
        c1 {
          x
          y
        }
        c2 {
          x
          y
        }
      }
      line2 {
        c1 {
          x
          y
        }
        c2 {
          x
          y
        }
      }
      image {
        id
        original_name
      }
    }
    lines {
      short_description
      c1 {
        x
        y
      }
      c2 {
        x
        y
      }
      image {
        id
        original_name
      }
    }
    pens {
      short_description
      coordinates {
        x
        y
      }
      image {
        id
        original_name
      }
    }
  }
`;

interface XYpos {
  x: number;
  y: number;
}

interface ImgType {
  id: string;
  original_name: string;
}

export interface BaseSuggested {
  short_description: string;
}

export interface ImgSuggested extends BaseSuggested {
  image: ImgType;
}

export interface ClassificationProbability {
  name: string;
  probability: number;
}

export interface SuggestedClassification extends BaseSuggested {
  [key: string]: ClassificationProbability[] | string | undefined;
  classes: ClassificationProbability[];
}

export interface SuggestedBox extends ImgSuggested {
  [key: string]: XYpos | string | number | ImgType | undefined;
  center: XYpos;
  height: number;
  width: number;
  angle: number;
}

export interface SuggestedCircle extends ImgSuggested {
  [key: string]: XYpos | string | ImgType | undefined;
  center: XYpos;
  radiusPoint: XYpos;
}

export interface SuggestedConnectedLines extends ImgSuggested {
  [key: string]: XYpos | string | ImgType | undefined;
  c1: XYpos;
  c2: XYpos;
  c3: XYpos;
}

export interface SuggestedIndependentLines extends ImgSuggested {
  [key: string]: { c1: XYpos; c2: XYpos } | number | string | ImgType | undefined;
  line1: { c1: XYpos; c2: XYpos };
  line2: { c1: XYpos; c2: XYpos };
}

export interface SuggestedLine extends ImgSuggested {
  [key: string]: XYpos | number | string | ImgType | undefined;
  c1: XYpos;
  c2: XYpos;
}
export interface SuggestedPen extends ImgSuggested {
  coordinates: XYpos[];
}

export interface SuggestedCoordinate extends ImgSuggested, XYpos {}

interface SuggestedLabelType {
  id: string;
  name: string;
}

export type AnySuggestion =
  | SuggestedBox
  | SuggestedCircle
  | SuggestedClassification
  | SuggestedConnectedLines
  | SuggestedCoordinate
  | SuggestedIndependentLines
  | SuggestedLine
  | SuggestedPen;

export interface SuggestedLabelsFrgmntType {
  [key: string]: SuggestedLabelType | string | AnySuggestion[] | undefined;
  task_type: SuggestedLabelType;
  report: string | undefined;
  boxes: SuggestedBox[];
  classifications: SuggestedClassification[];
  circles: SuggestedCircle[];
  connectedLines: SuggestedConnectedLines[];
  coordinates: SuggestedCoordinate[];
  independentLines: SuggestedIndependentLines[];
  lines: SuggestedLine[];
  pens: SuggestedPen[];
}
