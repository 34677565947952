import { XYpos } from '../types/XYpos';
import pointDistance from './pointDistance';

interface XYposDistance extends XYpos {
  distance: number;
}

const extendPointWithDistance = (innerPoint: XYpos, point: XYpos): XYposDistance => {
  const { distance } = pointDistance({ p1: innerPoint, p2: point });
  const ret = {
    distance,
    ...innerPoint,
  };
  return ret;
};

export default extendPointWithDistance;
