import gradient from 'gradient-color';
import getClassProbability from './getClassProbability';
import { ClassificationProbability } from '../../../../../../gql/fragments/task2label/suggestedLabels';
import { Classification } from '../../../../../../utils/Markers';

const clrs = ['#fefefe', '#e2e212', '#28a745'];
export const colorGradient = gradient(clrs, 101);

interface ButtonProbStyles {
  backgroundColor: string;
  color: string;
  fontWeight?: number;
  borderColor?: string;
}

export const getButtonWithProbabilitiesStyle = ({
  probability,
  isActivated,
}: {
  probability: number | undefined;
  isActivated: boolean;
}): ButtonProbStyles => {
  let color = '#515151';
  if (probability === undefined) {
    return {
      color,
      backgroundColor: clrs[0],
    };
  }

  const percentage = Math.min(Math.round(probability * 100), 100);
  const backgroundColor = colorGradient[percentage];

  if (percentage > 40 && percentage <= 80) {
    color = '#000000';
  } else if (percentage > 80) {
    color = '#ffffff';
  }

  return {
    color,
    backgroundColor,
    borderColor: gradient([backgroundColor, 'black'], 30)[1],
    fontWeight: isActivated ? 700 : 400,
  };
};

const getClassColor = (
  shortDescription: string,
  suggestedClasses: ClassificationProbability[] | undefined,
  classification: Classification,
) => {
  if (!suggestedClasses) return undefined;
  const probability = getClassProbability(shortDescription, suggestedClasses);
  if (probability === undefined) {
    // eslint-disable-next-line no-console
    console.warn(`No probability - why!? for ${shortDescription}`);
  }

  const isActivated =
    classification.isSuggested(shortDescription) ||
    !classification.options.choices.find((c) => c.short_description === shortDescription);
  return getButtonWithProbabilitiesStyle({ probability, isActivated });
};

export default getClassColor;
