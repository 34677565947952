/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import { Tooltip as StrapTip, TooltipProps } from 'reactstrap';
import ErrorBoundary from './ErrorHandling/Basic';

interface Props extends Omit<TooltipProps, 'target' | 'isOpen'> {
  tipId: string;
  children: React.ReactNode;
  tip: React.ReactNode;
}

const ToolTip = (props: Props) => {
  const [tooltipOpen, setTooltipOpen] = React.useState(false);
  const [activeTip, setActiveTip] = React.useState(false);

  React.useEffect(() => {
    setActiveTip(true);
  }, [setActiveTip]);

  const toggleActivate = React.useCallback(() => {
    setTooltipOpen(true);
  }, [setTooltipOpen]);

  const toggleDeactivate = React.useCallback(() => {
    setTooltipOpen(false);
  }, [setTooltipOpen]);

  const { tipId, tip, children, ...tooltipProps } = props;

  return (
    <span
      id={tipId}
      onFocus={toggleActivate}
      onBlur={toggleDeactivate}
      onMouseEnter={toggleActivate}
      onMouseOver={toggleActivate}
      onMouseLeave={toggleDeactivate}
    >
      {children}
      {activeTip && (
        <ErrorBoundary errorMessage="">
          <StrapTip {...tooltipProps} target={tipId} isOpen={tooltipOpen}>
            {tip}
          </StrapTip>
        </ErrorBoundary>
      )}
    </span>
  );
};

export default React.memo(ToolTip);
