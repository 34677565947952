import { produce } from 'immer';
import ImgMrkr, { Props as ImgProps, CoreData as ImgCoreData } from './ImgMrkr';
import { samePoint, purePosition, pointDistance, updatePoint } from './helpers';
import { SuggestedCircle } from '../../gql/fragments/task2label/suggestedLabels';
import { XYpos } from './types/XYpos';
import { GetPointsArguments, DrawArguments } from './types/general';

interface CircleSubmission {
  center: XYpos;
  radiusPoint: XYpos;
  short_description: string;
  image: string;
}

export interface CoreData extends ImgCoreData {
  center: XYpos | undefined;
  radiusPoint: XYpos | undefined;
  type: 'Circle';
}

export interface Props extends ImgProps {
  center?: XYpos;
  radiusPoint?: XYpos;
}
export const getRadius = (
  center: XYpos,
  radiusPoint: XYpos,
  width: number,
  height: number,
): number => {
  const { dx, dy } = pointDistance({ p1: center, p2: radiusPoint });
  return Math.sqrt((dx * width) ** 2 + (dy * height) ** 2);
};

export default class Circle extends ImgMrkr<Props, CoreData> {
  constructor(props: Props) {
    super(props, 'Circle');
  }

  get center() {
    return this.data.center;
  }

  get radiusPoint() {
    return this.data.radiusPoint;
  }

  getSubmissionObject(): CircleSubmission {
    const [center, radiusPoint] = this.getPoints({ useMovingPoint: false });
    if (!center || !radiusPoint) {
      throw new Error('The center & radiusPoint must be set before preparing submission object');
    }

    return {
      ...super.getImgSubmissionObject(),
      center,
      radiusPoint,
    };
  }

  setSuggested(vals: SuggestedCircle): Circle {
    super.setSuggested(vals);
    const { center, radiusPoint } = vals;
    this.data = produce<CoreData>(this.data, (draft) => {
      draft.center = purePosition(center);
      draft.radiusPoint = purePosition(radiusPoint);
    });
    return this.clone();
  }

  getIndex(point: XYpos): number {
    if (samePoint(point, this.center)) {
      return 0;
    }

    if (samePoint(point, this.radiusPoint)) {
      return 1;
    }

    return super.getIndex(point);
  }

  isDone(): boolean {
    if (!super.isDone()) return false;

    if (!this.center || !this.radiusPoint) return false;

    return true;
  }

  /**
   * Set the center and the radius for the line. If index is empty
   * it will first choose center and if this is set it will add the
   * radius/radiusPoint
   */
  setPoint(point: XYpos, index: number | undefined, imgId: string): Circle {
    if (!this.image) this.setImage(imgId);
    if (this.image !== imgId) return this;
    this.data = produce<CoreData>(this.data, (draft) => {
      draft.suggested = false;

      let elmnt2set: 'center' | 'radius';
      if (!this.isDone()) {
        if (!draft.center) {
          elmnt2set = 'center';
        } else {
          elmnt2set = 'radius';
        }
      } else {
        switch (index) {
          case 0:
            elmnt2set = 'center';
            break;
          case 1:
            elmnt2set = 'radius';
            break;
          default:
            throw new Error('The index must be 0 or 1');
        }
      }

      switch (elmnt2set) {
        case 'center':
          if (draft.center && draft.radiusPoint) {
            const { dx, dy } = pointDistance({ p1: draft.center, p2: point });
            if (draft.radiusPoint) {
              draft.radiusPoint = updatePoint(draft.radiusPoint, dx, dy);
            }
          }
          draft.center = point;
          break;
        case 'radius':
          draft.radiusPoint = point;
          break;
        default:
          break;
      }
    });

    return this.clone();
  }

  draw({ ctxt, width, height, scale, strokeStyle, fillStyle, ...otherArgs }: DrawArguments) {
    const [center, radiusPoint] = this.getPoints({ useMovingPoint: true });
    if (!center) return;
    strokeStyle = this.getStrokeStyle(strokeStyle); // eslint-disable-line
    ctxt.shadowColor = '#555555'; // eslint-disable-line
    ctxt.shadowBlur = 2; // eslint-disable-line

    if (radiusPoint) {
      const radius = getRadius(center, radiusPoint, width, height);

      ctxt.beginPath();
      ctxt.arc(
        Math.round(center.x * width),
        Math.round(center.y * height),
        radius,
        0,
        Math.PI * 2,
        true,
      );

      ctxt.strokeStyle = strokeStyle; // eslint-disable-line
      if (fillStyle) {
        ctxt.fillStyle = fillStyle; // eslint-disable-line
      }
      ctxt.stroke();
      ctxt.closePath();
    }
    ctxt.shadowColor = ''; // eslint-disable-line
    ctxt.shadowBlur = 0; // eslint-disable-line

    super.draw({
      ctxt,
      width,
      height,
      scale,
      fillStyle,
      strokeStyle,
      ...otherArgs,
    });
  }

  getPoints({ useMovingPoint = false }: GetPointsArguments = {}): [
    XYpos | undefined,
    XYpos | undefined,
  ] {
    let { center, radiusPoint } = this;
    if (useMovingPoint && this.movingPoint && center) {
      const point = this.movingPoint;
      if (this.movingIndex === 0) {
        const { dx, dy } = pointDistance({ p1: center, p2: point });
        center = point;
        // Move the radiusPoint if the center is beeing moved
        if (radiusPoint) {
          radiusPoint = updatePoint(radiusPoint, dx, dy);
        }
      } else if (this.movingIndex === 1) {
        radiusPoint = point;
      } else if (!radiusPoint && this.movingIndex === undefined) {
        radiusPoint = this.movingPoint;
      }
    }

    return [center, radiusPoint];
  }

  countUnsetPoints(): number {
    return 2 - this.getPoints().filter(Boolean).length;
  }
}
