import { MrkrTypes } from '../../../types';
import { LabelsKeys } from '../types/general';

export const getMarkerLabelName = (marker: MrkrTypes): LabelsKeys | undefined => {
  if (marker === 'Box') {
    return 'boxes';
  }

  if (marker === 'Circle') {
    return 'circles';
  }

  if (marker === 'Classification') {
    return 'classifications';
  }

  if (marker === 'Coordinate') {
    return 'coordinates';
  }

  if (marker === 'ConnectedLine') {
    return 'connectedLines';
  }

  if (marker === 'IndependentLine') {
    return 'independentLines';
  }

  if (marker === 'Line') {
    return 'lines';
  }

  if (marker === 'Pen') {
    return 'pens';
  }

  return undefined;
};
