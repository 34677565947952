import gql from 'graphql-tag';
import { typeFrgmnt, CoreTaskTypeWithSubTypes } from '../task2label/type';
import { suggestedLabelsFrgmnt, SuggestedLabelsFrgmntType } from '../task2label/suggestedLabels';
import { imgFrgmnt, ImgFrgmntType } from '../task2label/img';
import { labelsFrgmnt, LabelFrgmntType } from '../labels';
import { patientFrgmnt, PatientFrgmntType } from './patient';
import { studyDicomDataFrgmnt, StudyDicomFrgmntType } from './studyDicomData';

// eslint-disable-next-line import/prefer-default-export
export const currentTaskFrgmnt = gql`
  fragment taskFrgmnt on Task {
    id
    patient {
      ...patientFrgmnt
    }
    study_dicom_data {
      ...studyDicomDataFrgmnt
    }
    task_type {
      ...typeFrgmnt
      # Recursive in 6 levels
      # TODO: Should probably warn for incomplete task type if last level
      # is reached....
      task_types {
        ...typeFrgmnt
        task_types {
          ...typeFrgmnt
          task_types {
            ...typeFrgmnt
            task_types {
              ...typeFrgmnt
              task_types {
                ...typeFrgmnt
                task_types {
                  ...typeFrgmnt
                }
              }
            }
          }
        }
      }
    }
    images {
      ...imgFrgmnt
    }
    suggested_labels {
      task_type {
        id
        name
      }
      ...suggestedLabelsFrgmnt
    }
    labels {
      worker {
        id
      }

      labels_by_task_type {
        ...labelsFrgmnt
      }
    }
  }

  ${typeFrgmnt}
  ${suggestedLabelsFrgmnt}
  ${imgFrgmnt}
  ${patientFrgmnt}
  ${studyDicomDataFrgmnt}
  ${labelsFrgmnt}
`;

export interface CurrenTaskFrgmntType {
  id: string;
  exam_id: string;
  patient: PatientFrgmntType | undefined;
  study_dicom_data: StudyDicomFrgmntType | undefined;
  task_type: CoreTaskTypeWithSubTypes | undefined;
  images: ImgFrgmntType[];
  labels: LabelFrgmntType[];
  suggested_labels: (SuggestedLabelsFrgmntType & {
    task_type: {
      id: string;
      name: string;
    };
  })[];
}
