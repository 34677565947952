import gql from 'graphql-tag';
import { suggestedLabelsFrgmnt, SuggestedLabelsFrgmntType } from './suggestedLabels';
import { imgFrgmnt, ImgFrgmntType } from './img';
import { CoreTaskTypeWithSubTypes, typeFrgmnt } from './type';

// eslint-disable-next-line import/prefer-default-export
export const task2labelFrgmnt = gql`
  fragment task2labelFrgmnt on Task2Label {
    id
    task_type {
      ...typeFrgmnt
      # Recursive in 6 levels
      # TODO: Should probably warn for incomplete task type if last level
      # is reached....
      task_types {
        ...typeFrgmnt
        task_types {
          ...typeFrgmnt
          task_types {
            ...typeFrgmnt
            task_types {
              ...typeFrgmnt
              task_types {
                ...typeFrgmnt
                task_types {
                  ...typeFrgmnt
                }
              }
            }
          }
        }
      }
    }
    images {
      ...imgFrgmnt
    }
    suggested_labels {
      task_type {
        id
        name
      }
      ...suggestedLabelsFrgmnt
    }
  }

  ${suggestedLabelsFrgmnt}
  ${imgFrgmnt}
  ${typeFrgmnt}
`;

export type TaskTypeFrgmnt = CoreTaskTypeWithSubTypes;

export interface SuggestedWithTaskType extends SuggestedLabelsFrgmntType {
  task_type: {
    id: string;
    name: string;
  };
}

export interface Task2labelFrgmntType {
  id: string;
  exam_id: string;
  task_type: CoreTaskTypeWithSubTypes | undefined;
  images: ImgFrgmntType[];
  suggested_labels: SuggestedWithTaskType[];
}
