import { produce } from 'immer';
import { TOOLBAR_MAXIMIZE, TOOLBAR_MINIMIZE, TOOLBAR_MAXIMIZE_TOGGLE } from '../actions/types';
import { ToolBarState } from './types';

export default (state: ToolBarState = { maximized: true }, args: { type: string; txt: string }) =>
  produce<ToolBarState>(state, (draft) => {
    switch (args.type) {
      case TOOLBAR_MINIMIZE: {
        draft.maximized = false;
        break;
      }
      case TOOLBAR_MAXIMIZE: {
        draft.maximized = true;
        break;
      }
      case TOOLBAR_MAXIMIZE_TOGGLE: {
        draft.maximized = !draft.maximized;
        break;
      }
      default:
    }
  });
