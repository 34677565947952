import Box from '../Box';
import Circle from '../Circle';
import Classification from '../Classification';
import ConnectedLine from '../ConnectedLine';
import Coordinate from '../Coordinate';
import IndependentLine from '../IndependentLine';
import Line from '../Line';
import Pen from '../Pen';
import { AnyTool } from '../types';

export const getToolNameReadable = (tool?: AnyTool): string | undefined => {
  if (tool instanceof Box) {
    return 'Box';
  }

  if (tool instanceof Circle) {
    return 'Circle';
  }

  if (tool instanceof Classification) {
    return 'Classification';
  }

  if (tool instanceof Coordinate) {
    return 'Coordinate';
  }

  if (tool instanceof ConnectedLine) {
    return 'Connected line';
  }

  if (tool instanceof IndependentLine) {
    return 'Independent line';
  }

  if (tool instanceof Line) {
    return 'Line';
  }

  if (tool instanceof Pen) {
    return 'Pen';
  }

  return undefined;
};
