import { produce } from 'immer';

import {
  SETUP_IMAGES,
  STORE_LOADED_IMAGE,
  LOADING_IMAGE,
  FAILED_LOAD_IMAGE,
  UNLOAD_IMAGE,
  SET_MAX_RESOLUTION,
  SET_ONLY_FULL_RESOLUTION,
  ImageActions,
} from '../actions/types/images';
import { env } from '../config';
import { getLocalStoreInt } from './helpers';
import { ImagesState, LoadingState } from './types';

// Initial state of the application
const InitData: ImagesState = {
  available: [],
  loaded: [],
  loading: [],
  loadedQueue: [],
  unloadQueue: [],
  onlyFullResolution: false,
  maxResolution: getLocalStoreInt('images.maxResolution'),
};

const searchFnCreator = (needle: LoadingState) => (haystack: LoadingState) =>
  haystack.href === needle.href && haystack.sizeDescription === needle.sizeDescription;

export default (state: ImagesState = InitData, actionData: ImageActions) =>
  produce<ImagesState>(state, (draft) => {
    switch (actionData.type) {
      case SETUP_IMAGES: {
        const { imgs } = actionData;
        if (!imgs) throw new Error('No images provided');

        draft.available = imgs || [];
        break;
      }

      case LOADING_IMAGE: {
        const { href, sizeDescription } = actionData;
        if (!href) throw new Error('No href provided to loading image');
        if (!sizeDescription) throw new Error('No sizeDescription provided to loading image');
        const searchFn = searchFnCreator({ href, sizeDescription });
        if (
          !draft.loaded.find(({ href: h, img }) => h === href && img[sizeDescription]) &&
          !draft.loading.find(searchFn)
        ) {
          draft.loading.push({ href, sizeDescription });
        }
        break;
      }

      case FAILED_LOAD_IMAGE: {
        const { href, sizeDescription } = actionData;
        if (!href) throw new Error('No imgId provided to failed load of image');
        const searchFn = searchFnCreator({ href, sizeDescription });
        draft.loading = draft.loading.filter((load) => !searchFn(load));
        break;
      }

      case STORE_LOADED_IMAGE: {
        const { href, taskId, png, sizeDescription } = actionData;
        if (!href || !png || !taskId) {
          const args: { [key: string]: unknown } = {
            taskId,
            png,
            href,
          };
          const missingVars: string = Object.keys(args)
            .filter((k) => !args[k])
            .join("', '");
          throw new Error(
            `Invalid add img params. Could not find '${missingVars}' for ${sizeDescription}`,
          );
        }

        const searchFn = searchFnCreator({ href, sizeDescription });
        const loadingIdx = draft.loading.findIndex(searchFn);
        if (loadingIdx < 0) {
          if (draft.loaded.findIndex((i) => i.href === href && i.img[sizeDescription]) < 0) {
            png.destroy();
            // eslint-disable-next-line no-console
            console.log(
              `Aborted saving downloaded image ${href} as it wasn't in loading for ${sizeDescription}`,
              // debug: draft.loaded.map(i => i.href),
            );
          }
          break;
        } else {
          // Remove loading status
          draft.loading.splice(loadingIdx, 1);
        }

        // Add the new image
        const loadedImages4hrefIndex = draft.loaded.findIndex((i) => i.href === href);
        if (loadedImages4hrefIndex < 0) {
          draft.loaded.push({ href, img: { [sizeDescription]: png } });
        } else {
          draft.loaded[loadedImages4hrefIndex].img[sizeDescription] = png;
        }

        // Add to queue without the size info as we will drop all at once
        const queue = draft.loadedQueue.find(({ taskId: id }) => id === taskId);
        if (queue) {
          if (!queue.images.find((i) => i === href)) {
            queue.images.push(href);
          }
        } else {
          draft.loadedQueue.push({
            taskId,
            images: [href],
          });
        }

        // Clear images belonging to the x:th task.
        // Warning if the value is less than autoloaded it will delete the current task
        if (draft.loadedQueue.length > env.no_tasks_2_store) {
          const { images: taskImages2delete } = draft.loadedQueue[0];
          draft.loaded = draft.loaded.filter(
            ({ href: loaded }) => !taskImages2delete.includes(loaded),
          );
          draft.loadedQueue.splice(0, 1);
        }
        break;
      }

      case UNLOAD_IMAGE: {
        const { href, taskId } = actionData;
        if (!href || !taskId) {
          const args: { [key: string]: unknown } = {
            taskId,
            href,
          };
          const missingVars: string = Object.keys(args)
            .filter((k) => !args[k])
            .join("', '");
          throw new Error(`Invalid unload img params. Could not find '${missingVars}'`);
        }

        const index = draft.unloadQueue.push({ href, taskId });
        // Wait with unloading a few images as we may want to go back
        const noImages2retain = 10;
        if (index > noImages2retain) {
          for (let i = index - noImages2retain; i >= 0; i -= 1) {
            const image2unload = draft.unloadQueue[i];
            draft.unloadQueue.splice(i, 1);
            const image2destroy = state.loaded.find((img) => img.href === image2unload.href);
            if (image2destroy) {
              try {
                Object.values(image2destroy.img).forEach((v) => {
                  try {
                    v.destroy();
                    /* eslint-disable */
                    // @ts-ignore
                    v._parser = null;
                    /* eslint-enable */
                  } catch (error) {
                    // eslint-disable-next-line no-console
                    console.warn(`Failed to destroy ${v.id}`, error);
                  }
                });
              } catch (error) {
                // eslint-disable-next-line no-console
                console.warn('Failed to destroy image', image2unload.href, error);
              }
            }
            // Remove the image
            draft.loaded = draft.loaded.filter((img) => img.href !== image2unload.href);

            // Remove loading status
            draft.loading = draft.loading.filter((load) => load.href !== image2unload.href);

            // Update the image queue and delete any old tasks
            // this is to optimize the memory usage in the browser
            const queue = draft.loadedQueue.find(({ taskId: id }) => id === image2unload.taskId);
            if (queue) {
              queue.images = queue.images.filter((loaded) => loaded !== image2unload.href);
            }
          }
        }
        break;
      }

      case SET_MAX_RESOLUTION: {
        const { maxResolution } = actionData;
        // Setting less than 30 as the maxResolution should be impossible
        const minReasonableResolution = 30;
        let newResolution: number | undefined;
        if (
          maxResolution &&
          Number.isInteger(maxResolution) &&
          maxResolution > minReasonableResolution
        ) {
          newResolution = maxResolution;
        } else {
          newResolution = undefined;
        }
        draft.maxResolution = newResolution;
        localStorage.setItem('images.maxResolution', `${newResolution}`);
        break;
      }

      case SET_ONLY_FULL_RESOLUTION: {
        const { onlyFullResolution } = actionData;
        draft.onlyFullResolution = !!onlyFullResolution;
        break;
      }

      default:
    }
  });
