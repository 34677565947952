import ImgMrkr from '../../../utils/Markers/ImgMrkr';
import { AnyTool, AnyImgTool } from '../../../utils/Markers/types';
import { GeneralTaskFrgmntType } from '../../../gql/helpers/normalizeTypes';

const getImgs2Mark4Tool = (
  tool: AnyImgTool,
  task: GeneralTaskFrgmntType,
  toolLabels: AnyTool[],
): string[] => {
  if (!(tool instanceof ImgMrkr)) return [];
  const allImgs = task.images.map((img) => img.id);
  if (tool.multiple || !tool.task_type) return allImgs;

  if (tool.eachImg) {
    const subsetOfImgs = allImgs.filter((img) => {
      const lbls4img = toolLabels
        .filter((t): t is AnyImgTool => t instanceof ImgMrkr)
        .filter((t) => t.image && t.image === img);
      if (lbls4img.length === 0) return true;

      if (lbls4img.length < (tool.max || 1)) return true;

      return false;
    });
    return subsetOfImgs;
  }

  return allImgs;
};

export default getImgs2Mark4Tool;
