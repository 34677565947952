import { XYpos } from '../types/XYpos';

export function getMappedCoords<T extends XYpos | undefined>(
  position: T,
  width: number,
  height: number,
): T {
  if (position === undefined) return position;
  const truePos: XYpos = {
    x: position.x * width,
    y: position.y * height,
  };
  // @ts-ignore - not sure how to property refinement
  return truePos;
}

export function getMappedCoordsArray<T extends XYpos | undefined>(
  positions: T[],
  width: number,
  height: number,
): T[] {
  return positions.map((p) => getMappedCoords(p, width, height));
}
