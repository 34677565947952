import gql from 'graphql-tag';
import { RulerTypes } from '../../../types/Mrkr.types';

// eslint-disable-next-line import/prefer-default-export
export const markersFrgmnt = gql`
  fragment markersFrgmnt on MrkrCfg {
    short_description
    detailed_description
    marker
    options {
      choices {
        short_description
        detailed_description
        group_name
      }
      choice_groups {
        name
        min
        max
        activating_short_descriptions
        collapse
        shared_name_stem
      }
      each_img
      multiple
      min
      max
      ruler
      required
    }
  }
`;

interface MrkrCfgGnrl {
  /** A short description of what the marker is about.
Can be used as a button or similar short info to highligh what the
user is currently suppose to be measure */
  short_description: string;
  /** A long description that details the exact purpose of this
marker. */
  detailed_description?: string;
}

interface MrkrCfgOptionsGnrl {
  /** Indicator whether multiples are ok, e.g. when can multiple
matching classifications be ok */
  multiple?: boolean;
  /** The min value for anything, e.g. number of matching classes.
If at least one class has to match you can set this to 1 */
  min?: number;

  max?: number;

  /** Is the marker required or just optional? * */
  required?: boolean;
}

/**
  description:  The choices

 usually used for select boxes or dropdowns
*/
export interface MrkrChoice extends MrkrCfgGnrl {
  group_name: string | undefined;
}

export interface MrkrChoiceGroup {
  name: string;
  min?: number | undefined;
  max?: number | undefined;
  activating_short_descriptions: string[];
  collapse: boolean;
  shared_name_stem: boolean;
}

export interface MrkrCfgOptionsClassifications extends MrkrCfgOptionsGnrl {
  /** If you have chosen marker type of type Classification
you must provide a set of Classifications to choose from */
  choices: MrkrChoice[];
  choice_groups: MrkrChoiceGroup[];
}

/**
  description: The same type of marker may occur multiple times
*/
export interface MrkrCfgClassification extends MrkrCfgGnrl {
  /** The marker category to include */
  marker: 'Classification';
  /** Options that enhance basic marker categories */
  options: MrkrCfgOptionsClassifications;
}

export type MrkrNonSpecificTypes =
  | 'Box'
  | 'Circle'
  | 'Coordinate'
  | 'Pen'
  | 'ConnectedLine'
  | 'IndependentLine';

export interface MrkrOptions {
  /** Indicator whether multiples are ok, e.g. when can multiple
matching classifications be ok */
  multiple?: boolean;
  /** The min value for anything, e.g. number of matching classes.
If at least one class has to match you can set this to 1 */
  min?: number;

  max?: number;

  /** Is the marker required or just optional? * */
  required?: boolean;

  each_img?: boolean;
}

export interface MrkrCfgImage extends MrkrCfgGnrl {
  /** The marker category to include */
  marker: MrkrNonSpecificTypes;
  /** Options that enhance basic marker categories */
  options: MrkrOptions;
}

export interface MrkrLineOptions extends MrkrOptions {
  /** Ruler specific options indicating how a ruler should look/behave */
  ruler?: RulerTypes;
}

export interface MrkrCfgLine extends MrkrCfgGnrl {
  /** The marker category to include */
  marker: 'Line';
  /** Options that enhance basic marker categories */
  options: MrkrLineOptions;
}

export type AnyMrkrCfg = MrkrCfgClassification | MrkrCfgLine | MrkrCfgImage;
