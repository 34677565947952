import { LabelData } from '../types';

const findLabel = (typeId: string) => (l: LabelData) => {
  if (l.task_type) {
    return l.task_type.id === typeId;
  }
  return false;
};

export default findLabel;
