import { XYpos } from '../types/XYpos';

export interface DeltaWithDistance {
  dx: number;
  dy: number;
  distance: number;
  dx_mm?: number;
  dy_mm?: number;
  distance_mm?: number;
}

const pointDistance = ({
  p1,
  p2,
  width = 1,
  height = 1,
  pixelSpacingX,
  pixelSpacingY,
  scale,
}: {
  p1: XYpos;
  p2: XYpos;
  width?: number;
  height?: number;
  pixelSpacingX?: number | undefined;
  pixelSpacingY?: number | undefined;
  scale?: XYpos;
}): DeltaWithDistance => {
  let dx = p1.x - p2.x;
  let dy = p1.y - p2.y;
  let maxScale = 1;
  if (scale) {
    maxScale = Math.max(scale.x, scale.y);
    dx /= maxScale;
    dy /= maxScale;
  }

  const ret: DeltaWithDistance = {
    dx,
    dy,
    distance: Math.sqrt((dx * width) ** 2 + (dy * height) ** 2),
  };

  if (pixelSpacingY && pixelSpacingX) {
    ret.dx_mm = dx * width * pixelSpacingX * maxScale;
    ret.dy_mm = dy * height * pixelSpacingY * maxScale;
    ret.distance_mm = Math.sqrt(ret.dx_mm ** 2 + ret.dy_mm ** 2);
  }

  return ret;
};

export default pointDistance;
