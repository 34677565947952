import { Action as ReduxAction } from 'redux';

export const SET_REPORT_SIMPLE = 'SET_REPORT_SIMPLE';
export const TOGGLE_REPORT_SIMPLE = 'TOGGLE_REPORT_SIMPLE';

export interface SetReportSimpleAction extends ReduxAction {
  type: 'SET_REPORT_SIMPLE';
  simple: boolean;
}

export interface ToggleReportSimpleAction extends ReduxAction {
  type: 'TOGGLE_REPORT_SIMPLE';
}

export type ReportActions = SetReportSimpleAction | ToggleReportSimpleAction;
