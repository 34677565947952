import styled from 'styled-components';

const CenterBox = styled.div<{ height?: number; width?: number }>`
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: ${({ height = 400 }) => `-${Math.floor(height / 2)}px`};
  margin-left: ${({ width = 600 }) => `-${Math.floor(width / 2)}px`};
  width: ${({ width = 600 }) => `${width}px`};
  background-color: ${(p) => p.theme.background};
  color: ${(p) => p.theme.color};
`;

export default CenterBox;
