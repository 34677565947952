import { AnyTool } from '../../utils/Markers/types';

export const SAVE_LABEL = 'SAVE_LABEL';
export const SAVE_LABELS = 'SAVE_LABELS';
export const DELETE_LABEL = 'DELETE_LABEL';

export interface SaveLabelAction {
  type: 'SAVE_LABEL';
  taskId: string;
  tool: AnyTool;
}

export interface SaveLabelsAction {
  type: 'SAVE_LABELS';
  taskId: string;
  tools: AnyTool[];
}

export interface DeleteLabelAction {
  type: 'DELETE_LABEL';
  taskId: string;
  tool: AnyTool;
}

export type LabelActions = SaveLabelAction | SaveLabelsAction | DeleteLabelAction;
