import { DocumentNode } from 'graphql';
import gql from 'graphql-tag';

// eslint-disable-next-line import/prefer-default-export
export const campaignFrgmnt: DocumentNode = gql`
  fragment campaignFrgmnt on Campaign {
    id
    name
    price
    tasks_per_job
    thumbnail {
      id
    }
    task_type {
      restricted2users {
        id
        username
      }
      workers_per_task
    }
  }
`;

export interface CampaignFrgmntType {
  id: string;
  name: string;
  price: number;
  tasks_per_job: number;
  thumbnail: {
    id: string;
  };
  task_type: {
    restricted2users: {
      id: string;
      username: string;
    }[];
    workers_per_task: number;
  };
}
