export default (angle: number) => {
  let baseAngle = angle % 90;
  if (baseAngle <= 0) {
    baseAngle = Math.abs(baseAngle);
  }
  if (baseAngle < 45) {
    return {
      smallAngle: baseAngle,
      largeAngle: 90 - baseAngle,
    };
  }

  return {
    smallAngle: 90 - baseAngle,
    largeAngle: baseAngle,
  };
};
