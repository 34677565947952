import * as React from 'react';
import styled from 'styled-components';

const InventoryStyle = styled.div`
  position: absolute;
  right: 20px;
  bottom: 20px;
  padding: 5px;
  background-color: rgba(100, 100, 100, 0.3);
  border: 2px solid rgba(100, 100, 100, 0.1);
  color: rgb(220, 220, 220);
  border-radius: 5px;
  font-family: monospace;
  font-family: 0.8em;
`;

const Inventory = () => {
  if (!process.env.REACT_APP_INVENTORY_NO) return null;

  return (
    <InventoryStyle>
      {`Medical device software Inv. no:  ${process.env.REACT_APP_INVENTORY_NO}`}
    </InventoryStyle>
  );
};

export default Inventory;
