import Box from '../Box';
import Circle from '../Circle';
import Classification from '../Classification';
import ConnectedLine from '../ConnectedLine';
import Coordinate from '../Coordinate';
import IndependentLine from '../IndependentLine';
import Line from '../Line';
import Pen from '../Pen';
import { AnyTool } from '../types';
import { LabelsKeys } from '../types/general';

export const getToolLabelName = (tool: AnyTool): LabelsKeys | undefined => {
  if (tool instanceof Box) {
    return 'boxes';
  }

  if (tool instanceof Circle) {
    return 'circles';
  }

  if (tool instanceof Classification) {
    return 'classifications';
  }

  if (tool instanceof Coordinate) {
    return 'coordinates';
  }

  if (tool instanceof ConnectedLine) {
    return 'connectedLines';
  }

  if (tool instanceof IndependentLine) {
    return 'independentLines';
  }

  if (tool instanceof Line) {
    return 'lines';
  }

  if (tool instanceof Pen) {
    return 'pens';
  }

  return undefined;
};
