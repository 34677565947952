import produce from 'immer';
import { AnyTool } from '../../utils/Markers/types';
import { getToolLabelName } from '../../utils/Markers/utils';
import { ImgMrkr, ImgMrkrCoreData, ClassificationCoreData } from '../../utils/Markers';
import { LabelState } from '../types';

import { LabelsKeys } from '../../utils/Markers/types/general';
import findLabel from './findLabel';

interface Props {
  taskId: string;
  tool: AnyTool;
  state: LabelState;
}

export default ({ taskId, tool, state }: Props) =>
  produce(state, (draft) => {
    const toolName: LabelsKeys | undefined = getToolLabelName(tool);
    if (!toolName) throw new Error(`Could not identify the tool ${tool.constructor.name}`);
    const { task_type: taskType } = tool;
    if (!taskType) {
      throw new Error(
        `The tool ${tool.short_description} doesn't have a task
                         type - even free markers should have a type.`.replace(/[ ]{2,}/g, ' '),
      );
    }

    const taskState = draft[taskId];
    if (!taskState) {
      return;
    }

    // Find the label with the corresponding task type of the tool
    const existingLbls = taskState.find(findLabel(taskType.id));
    if (!existingLbls || !existingLbls[toolName]) return;
    const toolLbls = existingLbls[toolName];
    if (!toolLbls) throw new Error(`The ${toolName} hasn't been initiated!?`);

    const idx = toolLbls.findIndex((lbl: ImgMrkrCoreData | ClassificationCoreData) => {
      if (lbl.short_description !== tool.short_description) return false;
      if (lbl.imageMrkr && tool instanceof ImgMrkr) {
        // If the tool can be applied to multiple images and the current
        // label doesn't match then we save this as an additional label
        // and won't overwrite the previous label
        if (tool.isMultiImage() && lbl.image !== tool.image) return false;
      }
      return true;
    });
    if (idx < 0) return;

    toolLbls.splice(idx, 1);
  });
