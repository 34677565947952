import styled from 'styled-components';

const Container = styled.div<{ justifyContent?: string }>`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: ${({ justifyContent = 'inherit' }) => justifyContent};
  background: no-repeat center center scroll;
  background-size: cover;
  color: white;
  height: 100%;
`;

export default Container;
