import { produce } from 'immer';
import ImgMrkr, { Props as ImgProps, CoreData as ImgCoreData } from '../ImgMrkr';
import {
  samePoint,
  purePosition,
  getMappedCoords,
  getMappedCoordsArray,
  roundCoords,
  pointDistance,
} from '../helpers';
// eslint-disable-next-line import/no-cycle
import drawTline, { DrawTlineProps } from './drawTline';
import { XYpos } from '../types/XYpos';
import { SuggestedLine } from '../../../gql/fragments/task2label/suggestedLabels';
import { DrawArguments, GetPointsArguments } from '../types/general';
import getTextMidPosition from '../helpers/getTextMidPosition';

interface LineSubmission {
  c1: XYpos;
  c2: XYpos;
  short_description: string;
  image: string;
}

export interface LineType {
  c1: XYpos | undefined;
  c2: XYpos | undefined;
  drawLength: boolean;
}

export interface CoreData extends ImgCoreData, LineType {
  type: 'Line';
}

export interface Props extends ImgProps {
  c1?: XYpos;
  c2?: XYpos;
  drawLength?: boolean;
}
export default class Line extends ImgMrkr<Props, CoreData> {
  constructor(props: Props) {
    const { drawLength = true } = props;
    super(props, 'Line');

    if (!(props instanceof Line)) {
      this.data = produce<CoreData>(this.data, (draft) => {
        if (draft.drawLength === undefined) {
          draft.drawLength = drawLength;
        }
      });
    }
  }

  get c1() {
    return this.data.c1;
  }

  get c2() {
    return this.data.c2;
  }

  get drawLength() {
    return this.data.drawLength;
  }

  getSubmissionObject(): LineSubmission | null {
    if (this.requireDone('line') === null) {
      return null;
    }

    const [c1, c2] = this.getPoints({ useMovingPoint: false });
    if (!c1 || !c2) throw new Error('The c1 & c2 must be set before preparing submission object');
    return {
      ...super.getImgSubmissionObject(),
      c1,
      c2,
    };
  }

  setSuggested({ c1, c2, ...vals }: SuggestedLine): Line {
    super.setSuggested(vals);
    this.data = produce<CoreData>(this.data, (draft) => {
      draft.c1 = purePosition(c1);
      draft.c2 = purePosition(c2);
    });
    return this.clone();
  }

  isDone(): boolean {
    if (!super.isDone()) return false;

    if (this.c1 && this.c2) return true;

    return false;
  }

  getIndex(point: XYpos): number {
    if (samePoint(point, this.c1)) {
      return 0;
    }

    if (samePoint(point, this.c2)) {
      return 1;
    }

    return super.getIndex(point);
  }

  /**
   * Set the position points for the line. If index is left empty
   * it will choose the last position.
   */
  setPoint(point: XYpos, index: number | undefined, imgId: string): Line {
    if (!this.image) this.setImage(imgId);
    if (this.image !== imgId) return this;
    this.data = produce<CoreData>(this.data, (draft) => {
      draft.suggested = false;

      let elmnt2set: 'c1' | 'c2';
      if (!this.isDone()) {
        if (!draft.c1) {
          elmnt2set = 'c1';
        } else {
          elmnt2set = 'c2';
        }
      } else {
        switch (index) {
          case 0:
            elmnt2set = 'c1';
            break;
          case 1:
            elmnt2set = 'c2';
            break;
          default:
            throw new Error('The index must be 0 or 1');
        }
      }

      switch (elmnt2set) {
        case 'c1':
          draft.c1 = point;
          break;
        case 'c2':
          draft.c2 = point;
          break;
        default:
          break;
      }
    });

    return this.clone();
  }

  draw({ ctxt, width, height, scale, strokeStyle, fillStyle, ...otherArgs }: DrawArguments) {
    const [c1, c2] = this.getPoints({ useMovingPoint: true });
    if (!c1) return;

    const stroke = this.getStrokeStyle(strokeStyle); // eslint-disable-line
    ctxt.strokeStyle = stroke; // eslint-disable-line
    ctxt.fillStyle = fillStyle || stroke; // eslint-disable-line
    ctxt.lineJoin = ctxt.lineCap = 'round'; // eslint-disable-line
    ctxt.shadowColor = '#555555'; // eslint-disable-line
    ctxt.shadowBlur = 2; // eslint-disable-line

    if (c2) {
      const { x: x1, y: y1 } = roundCoords(getMappedCoords(c1, width, height));
      const { x: x2, y: y2 } = roundCoords(getMappedCoords(c2, width, height));
      // Draw the actual line
      ctxt.beginPath();
      ctxt.moveTo(x1, y1);
      ctxt.lineTo(x2, y2);
      ctxt.fill();
      ctxt.stroke();
      ctxt.closePath();

      const { distance_mm: trueRulerLength, distance: rawRulerLength } = pointDistance({
        ...otherArgs,
        width,
        height,
        p1: c1,
        p2: c2,
        scale,
      });

      if (this.options && this.options.ruler) {
        const { ruler } = this.options;
        if (rawRulerLength > 0) {
          type Args = Pick<DrawTlineProps, 'ctxt' | 'rulerLength' | 'width' | 'height' | 'scale'>;
          const tlineArgs: Args = {
            ctxt,
            rulerLength: rawRulerLength * 0.5,
            width,
            height,
            scale,
          };

          if (ruler === 'start' || ruler === 'both') {
            drawTline({ startPos: c1, endPos: c2, ...tlineArgs });
          }
          if (ruler === 'end' || ruler === 'both') {
            drawTline({ startPos: c2, endPos: c1, ...tlineArgs });
          }
        }
      }

      if (this.options.drawLength || this.drawLength) {
        const { x = 0.5, y = 0.5 } = this.midPoint() || {};
        const distance = trueRulerLength ? trueRulerLength.toFixed(1) : rawRulerLength.toFixed(0);
        const unit = trueRulerLength ? 'mm' : 'px';
        ctxt.strokeStyle = 'white'; // eslint-disable-line
        const { xpos, align } = getTextMidPosition({
          xCenter: x * width,
          canvasPixelWidth: width,
        });
        if (align) {
          // eslint-disable-next-line no-param-reassign
          ctxt.textAlign = align;
        }

        ctxt.strokeText(`${distance} ${unit}`, xpos, y * height);
      }
    }
    ctxt.shadowColor = ''; // eslint-disable-line
    ctxt.shadowBlur = 0; // eslint-disable-line

    super.draw({
      ctxt,
      width,
      height,
      scale,
      strokeStyle,
      fillStyle,
      ...otherArgs,
    });
  }

  getPoints({ useMovingPoint = false }: GetPointsArguments = {}): (XYpos | undefined)[] {
    let { c1, c2 } = this;

    if (useMovingPoint && this.movingPoint) {
      if (!this.isDone() && this.movingIndex === undefined) {
        c2 = c2 || this.movingPoint;
      } else if (this.isDone()) {
        if (this.movingIndex === 0) {
          c1 = this.movingPoint;
        } else if (this.movingIndex === 1) {
          c2 = this.movingPoint;
        }
      }
    }

    return [c1, c2];
  }

  countUnsetPoints(): number {
    return 2 - this.getPoints().filter(Boolean).length;
  }

  midPoint(): XYpos | undefined {
    const [c1, c2] = this.getPoints({ useMovingPoint: true });
    if (!c1 || !c2) return undefined;

    return {
      x: c1.x - (c1.x - c2.x) / 2,
      y: c1.y - (c1.y - c2.y) / 2,
    };
  }

  flipDirection() {
    if (!this.isDone()) return;

    const { c1, c2 } = this;
    this.data = produce<CoreData>(this.data, (draft) => {
      draft.c1 = c2;
      draft.c2 = c1;
    });
  }

  angle(radians = false, width: number, height: number): number | undefined {
    const points = this.getPoints({ useMovingPoint: true }).filter(Boolean);
    const [c1, c2] = getMappedCoordsArray(points, width, height);
    if (!c1 || !c2) return undefined;

    const dx = c2.x - c1.x;
    const dy = c2.y - c1.y;

    let theta = Math.atan2(dy, dx); // range (-PI, PI]
    if (radians) return theta;

    theta *= 180 / Math.PI; // rads to degs, range (-180, 180]
    // if (theta < 0) theta = 360 + theta; // range [0, 360)
    return theta;
  }
}
