import gql from 'graphql-tag';

// eslint-disable-next-line import/prefer-default-export
export const sithsFrgmnt = gql`
  fragment sithsFrgmnt on SITHS {
    countryName
    localityName
    organizationName
    commonName
    surname
    givenName
    HSAID
    emailAddress
    title
  }
`;

export interface SITHSFrgmntType {
  countryName: string; // 'se',
  localityName: string; // 'Stockholms län',
  organizationName: string; // 'Stockholms Läns Landsting'
  commonName: string;
  surname: string;
  givenName: string;
  serialNumber: string; // Has the format 'SE....-HSAID'
  // eslint-disable-next-line @typescript-eslint/naming-convention
  HSAID: string;
  emailAddress: string;
  title: string;
}
