import { produce } from 'immer';
import ImgMrkr, { Props as ImgProps, CoreData as ImgCoreData } from './ImgMrkr';
import { samePoint, purePosition, find3ptAngle } from './helpers';
import { XYpos } from './types/XYpos';
import { SuggestedConnectedLines } from '../../gql/fragments/task2label/suggestedLabels';
import { GetPointsArguments, DrawArguments } from './types/general';

interface ConnectedLineSubmission {
  c1: XYpos;
  c2: XYpos;
  c3: XYpos;
  short_description: string;
  image: string;
}

export interface CoreData extends ImgCoreData {
  c1: XYpos | undefined;
  c2: XYpos | undefined;
  c3: XYpos | undefined;
  type: 'ConnectedLine';
}

export interface Props extends ImgProps {
  c1?: XYpos;
  c2?: XYpos;
  c3?: XYpos;
}

export default class ConnectedLine extends ImgMrkr<Props, CoreData> {
  constructor(props: Props) {
    super(props, 'ConnectedLine');
  }

  get c1() {
    return this.data.c1;
  }

  get c2() {
    return this.data.c2;
  }

  get c3() {
    return this.data.c3;
  }

  getSubmissionObject(): ConnectedLineSubmission {
    const [c1, c2, c3] = this.getPoints({ useMovingPoint: false });
    if (!c1 || !c2 || !c3)
      throw new Error('The c1, c2 & c3 must be set before preparing submission object');
    return {
      ...super.getImgSubmissionObject(),
      c1,
      c2,
      c3,
    };
  }

  setSuggested(vals: SuggestedConnectedLines): ConnectedLine {
    super.setSuggested(vals);
    this.data = produce<CoreData>(this.data, (draft) => {
      draft.c1 = purePosition(vals.c1);
      draft.c2 = purePosition(vals.c2);
      draft.c3 = purePosition(vals.c3);
    });
    return this.clone();
  }

  isDone(): boolean {
    if (!super.isDone()) return false;

    if (!this.c1 || !this.c2 || !this.c3) return false;

    return true;
  }

  getIndex(point: XYpos): number {
    if (samePoint(point, this.c1)) {
      return 0;
    }

    if (samePoint(point, this.c2)) {
      return 1;
    }

    if (samePoint(point, this.c3)) {
      return 2;
    }

    return super.getIndex(point);
  }

  setPoint(point: XYpos, index: number | undefined, imgId: string): ConnectedLine {
    if (!this.image) this.setImage(imgId);
    if (this.image !== imgId) return this;
    this.data = produce<CoreData>(this.data, (draft) => {
      draft.suggested = false;

      let elmnt2set: 'c1' | 'c2' | 'c3';
      if (!this.isDone()) {
        if (!draft.c1) {
          elmnt2set = 'c1';
        } else if (!draft.c2) {
          elmnt2set = 'c2';
        } else {
          elmnt2set = 'c3';
        }
      } else {
        switch (index) {
          case 0:
            elmnt2set = 'c1';
            break;
          case 1:
            elmnt2set = 'c2';
            break;
          case 2:
            elmnt2set = 'c3';
            break;
          default:
            throw new Error('The index must be 1, 2 or 3');
        }
      }

      switch (elmnt2set) {
        case 'c1':
          draft.c1 = point;
          break;
        case 'c2':
          draft.c2 = point;
          break;
        case 'c3':
          draft.c3 = point;
          break;
        default:
          break;
      }
    });

    return this.clone();
  }

  drawTxt({
    ctxt,
    width,
    height,
  }: {
    ctxt: CanvasRenderingContext2D;
    width: number;
    height: number;
  }) {
    const [c1, c2, c3] = this.getPoints({ useMovingPoint: true });
    if (!c1 || !c2 || !c3) {
      return;
    }
    const angle = (find3ptAngle(c1, c2, c3) * 180) / Math.PI;

    let { x, y } = c2;
    x *= width;
    y *= height;

    const sign = (a: number, b: number) => (a - b < 0 ? -1 : 1);
    const signX = (sign(c2.x, c1.x) + sign(c2.x, c3.x)) / 2;
    const signY = (sign(c2.y, c1.y) + sign(c2.y, c3.y)) / 2;
    const offset = 24;
    x += signX * offset;
    y += signY * offset;
    // When the line is diagonal then the text needs to be moved away from
    // the lines in a +- fashion
    if (!signX && !signY && sign(c2.x, c1.x)) {
      x -= sign(c2.x, c1.x) * offset;
      y += sign(c2.y, c1.y) * offset;
    }
    ctxt.strokeStyle = 'white'; // eslint-disable-line
    ctxt.textAlign = 'center'; // eslint-disable-line
    ctxt.textBaseline = 'middle'; // eslint-disable-line
    ctxt.strokeText(`${angle.toFixed(1)}°`, x, y);
  }

  draw({ ctxt, width, height, scale, strokeStyle, fillStyle, ...otherArgs }: DrawArguments) {
    strokeStyle = this.getStrokeStyle(strokeStyle); // eslint-disable-line
    ctxt.strokeStyle = strokeStyle; // eslint-disable-line
    ctxt.fillStyle = fillStyle || strokeStyle; // eslint-disable-line

    const [c1, c2, c3] = this.getPoints({ useMovingPoint: true });
    if (!c1) return;

    if (c2) {
      // Draw the actual line
      ctxt.beginPath();
      ctxt.moveTo(Math.round(c1.x * width), Math.round(c1.y * height));
      ctxt.lineTo(Math.round(c2.x * width), Math.round(c2.y * height));
      if (c3) {
        ctxt.lineTo(Math.round(c3.x * width), Math.round(c3.y * height));
        this.drawTxt({ ctxt, width, height });
      }
      ctxt.stroke();
      ctxt.closePath();
    }

    super.draw({
      ctxt,
      width,
      height,
      scale,
      fillStyle,
      strokeStyle,
      ...otherArgs,
    });
  }

  getPoints({ useMovingPoint = false }: GetPointsArguments = {}): [
    XYpos | undefined,
    XYpos | undefined,
    XYpos | undefined,
  ] {
    let { c1, c2, c3 } = this;

    if (useMovingPoint && this.movingPoint) {
      const point = this.movingPoint;
      if (this.movingIndex === 0) {
        c1 = point;
      } else if (!c2 || this.movingIndex === 1) {
        c2 = point;
      } else if (!c3 || this.movingIndex === 2) {
        c3 = point;
      }
    }
    return [c1, c2, c3];
  }

  countUnsetPoints(): number {
    return 3 - this.getPoints().filter(Boolean).length;
  }
}
