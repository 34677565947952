import * as React from 'react';
import { Card, CardBody, CardHeader, Alert } from 'reactstrap';

interface Props {
  header: React.ReactNode;
  children: React.ReactNode;
  color?: string;
  maxWidth?: string;
}

interface State {
  componentError?: string;
}

class CardWithErrorCatching extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { componentError: undefined };
  }

  componentDidCatch(error: Error) {
    this.setState({ componentError: error.message });
  }

  render() {
    const { header, children, color = '#222', maxWidth = '300px' } = this.props;
    const { componentError } = this.state;
    return (
      <Card style={{ color, maxWidth }}>
        <CardHeader>{header}</CardHeader>
        <CardBody>
          {!componentError && children}
          {componentError && (
            <Alert color="danger">Sorry component errored: {componentError}</Alert>
          )}
        </CardBody>
      </Card>
    );
  }
}

export default CardWithErrorCatching;
