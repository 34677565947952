/* eslint-disable no-underscore-dangle */
import { combineReducers, createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';

import rootReducer from './reducers';

const middleWare = [applyMiddleware(thunk)];
// If you are using the devToolsExtension, you can add it here also
// eslint-disable-next-line no-underscore-dangle
if ('__REDUX_DEVTOOLS_EXTENSION__' in window) {
  // @ts-ignore - __REDUX_DEVTOOLS_EXTENSION__ complaint
  middleWare.push(window.__REDUX_DEVTOOLS_EXTENSION__());
}

const store = createStore(
  combineReducers(rootReducer),
  {}, // initial state
  compose(...middleWare),
);

export default store;
