import { Action as ReduxAction } from 'redux';
import { TaskWithNrmlzdType } from '../../gql/helpers/normalizeTypes';

export const CLEAR_CAMPAIGN = 'CLEAR_CAMPAIGN';
export const ADD_WORK = 'ADD_WORK';
export const ARCHIVED_WORK = 'ARCHIVED_WORK';

export interface ClearCampaignAction extends ReduxAction {
  type: 'CLEAR_CAMPAIGN';
  campaignId: string;
}

export interface AddWorkAction extends ReduxAction {
  type: 'ADD_WORK';
  campaignId: string;
  name: string;
  tasks2do: TaskWithNrmlzdType[];
}

export interface ArchiveWorkAction extends ReduxAction {
  type: 'ARCHIVED_WORK';
  campaignId: string;
  name: string;
  taskId: string;
}

export type WorkActions = ClearCampaignAction | AddWorkAction | ArchiveWorkAction;
