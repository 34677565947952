import { getMappedCoords, roundCoords } from '../helpers';
import { XYpos } from '../types/XYpos';

export interface PerpProps {
  startPos: XYpos;
  endPos: XYpos;
  width: number;
  height: number;
  rulerLength: number;
}

const getPerpendicularPoints = ({
  startPos,
  endPos,
  width,
  height,
  rulerLength,
}: PerpProps): {
  from: XYpos;
  to: XYpos;
} => {
  const { y: y1, x: x1 } = getMappedCoords(startPos, width, height);
  const { y: y2, x: x2 } = getMappedCoords(endPos, width, height);

  // Perpendicular line length in total
  const endLen = Math.max(
    Math.min(width, height) / 40,
    10,
    Math.min(Math.min(width, height) / 15, rulerLength / 3),
  );
  if (x2 === x1) {
    return {
      from: roundCoords({ x: x1 + endLen, y: y1 }),
      to: roundCoords({ x: x1 - endLen, y: y1 }),
    };
  }

  let px = y1 - y2; // as vector at 90 deg to the line
  let py = x2 - x1;
  const len = endLen / Math.hypot(px, py);
  px *= len;
  py *= len;

  return {
    from: roundCoords({ x: x1 + px, y: y1 + py }),
    to: roundCoords({ x: x1 - px, y: y1 - py }),
  };
};

export default getPerpendicularPoints;
