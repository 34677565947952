import gql from 'graphql-tag';

// eslint-disable-next-line import/prefer-default-export
export const imgFrgmnt = gql`
  fragment imgFrgmnt on Img {
    id
    href
    original_name
    pixel_spacing_x
    pixel_spacing_y
    exam_view
    suggested_exam_view
    suggested_exam_view_probability
    bad_pic
  }
`;

export interface ImgFrgmntType {
  id: string;
  href: string;
  original_name: string | undefined;
  pixel_spacing_x: number | undefined;
  pixel_spacing_y: number | undefined;
  exam_view: string | undefined;
  suggested_exam_view: string | undefined;
  suggested_exam_view_probability: number | undefined;
}
