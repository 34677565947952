import { MrkrChoice, MrkrChoiceGroup } from '../../../gql/fragments/task2label/markers';

interface Props {
  activator?: string | undefined;
  choiceGroups: MrkrChoiceGroup[];
  choices: MrkrChoice[];
}

export interface Groups extends MrkrChoiceGroup {
  members: MrkrChoice[];
}

export default (props: Props) => {
  const { activator, choiceGroups, choices } = props;
  const groups = choiceGroups.map<Groups>((cg) => ({
    ...cg,
    members: choices.filter((c) => c.group_name === cg.name),
  }));

  if (!activator) {
    return groups;
  }

  const activeGroups: Groups[] = groups.filter(({ activating_short_descriptions: as }) =>
    as.find((a) => a === activator),
  );
  return activeGroups;
};
