import * as React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import styled, { keyframes } from 'styled-components';
import Draggable from 'react-draggable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import FormattedText from './FormattedText';
import ShortcutsImg from './Keyboard_shortcuts.svg';
import TooltipButton from './TooltipButton';
import { getButtonWithProbabilitiesStyle } from '../containers/Viewer/ImgViewer/ImgToolbar/ClassificationTool/helpers/getClassColor';
import { probabilitySelectionThreshold } from '../config';

interface Props {
  info: string;
  className?: string;
}

const reportOnTheRight = process.env.REACT_APP_ACTIVATE_REPORT || process.env.REACT_APP_DEMO;
const ButtonWrapper = styled.div`
  position: absolute;
  z-index: 100;
  bottom: 20px;
  right: ${reportOnTheRight ? 'inherit' : '20px'};
  left: ${!reportOnTheRight ? 'inherit' : '20px'};
`;

const InfoAnimation = keyframes`
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-20px);
  }
  60% {
    transform: translateY(-10px);
  }
`;

const ButtonBackgroundParagraph = styled.p`
  background-color: darkgray;
  padding: 10px;
  border-radius: 5px;
  margin-left: 20px;
  margin-right: 20px;
`;

const DivButton = styled(Button)`
  display: block;
  margin-top: 4px;
  margin-bottom: 4px;
`;

const StyledTooltipButton = styled(TooltipButton)`
  overflow: hidden;
  border-radius: 50%;
  box-shadow: 5px 7px 10px 2px rgba(31, 31, 31, 0.5);
  @media screen and (max-width: 800px) {
    font-size: 10px;
  }

  animation: ${InfoAnimation} 2s forwards;
  animation-delay: 1s;
`;

const ActivatorInput = styled.div<{ mouse?: boolean }>`
  display: inline-block;
  font-weight: bold;
  margin-top: 3px;
  margin-right: 2px;
  margin-left: 2px;
  border-radius: 5px;
  padding: 3px;
  background-color: ${({ mouse }) => (mouse ? 'black' : '#c8beb7')};
  color: ${({ mouse }) => (mouse ? '#fff' : '#000')};
`;

const InfoModal = (props: Props) => {
  const [open, setOpen] = React.useState(false);
  const toggle = React.useCallback(
    (event?: React.MouseEvent<HTMLButtonElement> | undefined) => {
      if (event && event.shiftKey && !open) {
        return;
      }
      setOpen(!open);
    },
    [open, setOpen],
  );

  const { className, info } = props;
  return (
    <>
      <Modal isOpen={open} toggle={toggle} className={className}>
        <ModalHeader toggle={toggle}>Help</ModalHeader>
        <ModalBody>
          <FormattedText value={info} />
          <h1>General instructions</h1>
          <p>
            Each task has a set of labels (alias markers) that can be set. The labels can be global,
            e.g. classes such as fracture yes/no, or they may be specific measure for a specific
            image, e.g. the dorsal angulation of the radius on the lateral view.
          </p>
          <p>
            Some measures are required and must be completed before the submission is allowed. When
            the labels are complete the submission button changes color into a more saturated green.
          </p>
          <p>
            Once you have finished a label the system will automatically switch to the next label
            that isn&rsquo;t yet finished. Some labels may have that each image can have a label,
            e.g. the bounding box of a fracture can be relevant for more than one image, and then
            you will notice that you are blocked from the previous image while you still have the
            same tool selected. There may also be a case where the same label may occurr multiple
            times, e.g. when marking of osteophytes it makes sense to allow more than one indicator.
          </p>
          <h2>Keyboard shortcuts</h2>
          <p>In order to speed up labeling there are some keyboard shortcuts available:</p>
          <img src={ShortcutsImg} alt="Keyboard shortcut map" width="100%" />
          <ul style={{ marginTop: '20px' }}>
            <li>
              <ActivatorInput style={{ backgroundColor: '#afc6e9' }}>1-9</ActivatorInput>
              selects corresponding classicaiton number if such exists
            </li>
            <li>
              <ActivatorInput style={{ backgroundColor: '#ff9955' }}>Q,W,E,R,T,Y</ActivatorInput>
              toggles selection corresponding images if such exists
            </li>
            <li>
              <ActivatorInput style={{ backgroundColor: 'steelblue' }}>D</ActivatorInput>
              Activates the line draw tool (unliess there are predefined lines)
            </li>
            <li>
              <ActivatorInput style={{ backgroundColor: '#d45500', color: '#fff' }}>
                Left/Right
              </ActivatorInput>
              moves the selection to the left or the right
            </li>
            <li>
              <ActivatorInput style={{ backgroundColor: '#803300', color: '#fff' }}>
                Up/Down
              </ActivatorInput>
              toggles the toolbar meny between maximized and minimized
            </li>
            <li>
              <ActivatorInput style={{ backgroundColor: '#5cb85c', color: '#fff' }}>
                Return
              </ActivatorInput>
              submits the task if it is marked as done. (see when the color of the submit button has
              changed color)
            </li>
            <li>
              <ActivatorInput style={{ backgroundColor: '#e9afaf' }}>Escape</ActivatorInput>
              quit any selected tool
            </li>
            <li>
              <ActivatorInput style={{ backgroundColor: '#d35f5f', color: '#fff' }}>
                Delete/Backspace
              </ActivatorInput>
              delete selected tool
            </li>
          </ul>
          <h2>Image manipulation</h2>
          <p>
            If you want to manipulate the image you use the <strong>mouse (+ keyboard)</strong>:
          </p>
          <ul style={{ marginTop: '20px' }}>
            <li>
              <strong>Panoration: </strong>
              <ActivatorInput mouse>Left mouse-button</ActivatorInput>
              or if you have selected a tool
              <ActivatorInput>Shift</ActivatorInput>+
              <ActivatorInput mouse>Left mouse-button</ActivatorInput>
              triggers the image panoration. This requires that the image is zoomed i.e. and the
              image doesn&rquot;t fit within the window.
            </li>
            <li>
              <strong>Threshold: </strong>
              <ActivatorInput>Ctrl</ActivatorInput>+
              <ActivatorInput mouse>Left mouse-button</ActivatorInput>
              or
              <ActivatorInput>Alt</ActivatorInput>+<ActivatorInput>Shift</ActivatorInput>+
              <ActivatorInput mouse>Left mouse-button</ActivatorInput>
              or
              <ActivatorInput mouse>center mouse-button</ActivatorInput>
              triggers the threshold tool where you can change the grayscale range of the image.
              Move mouse to the left and the image becomes darker and vice versa. If you want to
              reset the threshold then close and re-open the image.
              <strong>Note </strong>
              if you have selected a marker (e.g. a line or box) then the mouse button triggers the
              drawing function. Similarly if the mouse pointer is hovering over a drawn element it
              may select that item.
            </li>
            <li>
              <strong>Zoom: </strong>
              <ActivatorInput mouse>Scroll</ActivatorInput>
              or
              <ActivatorInput>Alt</ActivatorInput>+
              <ActivatorInput mouse>Left mouse-button</ActivatorInput>
              triggers the image zoom. The image is zoomed relative to the position of the mouse
              pointer. Scroll upwards and the image is zoomed vice versa. If you have changed the
              behavior of your mouse scroll this may be the opposite.
            </li>
          </ul>
          <h2>Button colors</h2>
          <h3>Without predictions</h3>
          <p>
            Some sections of the application does not have predictions and then the colors are
            simply selected &amp; unselected. <i>Note</i> that an algorithm may provide predictions
            for one group while the another does not have predictions and requires manual selection
            for activating classes. The buttons will have two possible colors:
          </p>
          <ButtonBackgroundParagraph>
            <Button color="light">Unselected</Button>
            &nbsp;
            <Button color="primary">Selected</Button>
          </ButtonBackgroundParagraph>
          <p>
            The system is designed so that options will appear as you click different combinations
            of buttons. As many classifications can be over 30-50 classes we thereby limit the
            number of visible clutter.
          </p>
          <h3>With predicitons</h3>
          <p>
            When the algorithm has delivered probabilities for classes you will notice that buttons
            change colors. The buttons where the algorithm has not had enough predictions for
            training and providing a prediction it will be gray:
          </p>
          <ButtonBackgroundParagraph>
            <Button>No prediction</Button>
          </ButtonBackgroundParagraph>
          <p>
            Once we have probabilities we get a range where the probability is indicated by the
            color. Once over {probabilitySelectionThreshold * 100}% the class becomes selected and
            the button font becomes <strong>bold</strong>. The probability is not explicitly written
            as it is a medical decision that should not be decided depending on small probability
            changes. There are though ranges and below you can see the key components of the span
            displayed:
          </p>
          <ButtonBackgroundParagraph>
            <DivButton
              style={getButtonWithProbabilitiesStyle({ probability: 0, isActivated: false })}
            >
              0% probability
            </DivButton>
            <DivButton
              style={getButtonWithProbabilitiesStyle({ probability: 0.5, isActivated: false })}
            >
              50% probability
            </DivButton>
            <DivButton
              style={getButtonWithProbabilitiesStyle({
                probability: probabilitySelectionThreshold,
                isActivated: true,
              })}
            >
              {probabilitySelectionThreshold * 100}% probability and selected
            </DivButton>
            <DivButton
              style={getButtonWithProbabilitiesStyle({ probability: 1, isActivated: true })}
            >
              100% probability and selected
            </DivButton>
          </ButtonBackgroundParagraph>
          <p>
            Once you have clicked on a class you will loose the probabilities and all you can toggle
            between is the selected/unselected class. If you encounter a button has been selected by
            being over the {probabilitySelectionThreshold * 100}% threshold you can unselect it by
            clicking on the button again. If you want to see the the probabilities again you need to
            refresh the page by pressing &quot;F5&quot; or clicking refresh.
          </p>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
      <Draggable>
        <ButtonWrapper>
          {!open && (
            <StyledTooltipButton
              id="help"
              tip="Click for help"
              formatted={false}
              onClick={toggle}
              color="info"
            >
              <FontAwesomeIcon icon={faInfoCircle} size="5x" />
            </StyledTooltipButton>
          )}
        </ButtonWrapper>
      </Draggable>
    </>
  );
};

export default React.memo(InfoModal);
