import gql from 'graphql-tag';
import * as conditionsFrgmnt from './conditions';
import * as markersFrgmnt from './markers';

// eslint-disable-next-line import/prefer-default-export
export const typeFrgmnt = gql`
  fragment coreTypeFrgmnt on TaskType {
    id
    name
    instructions
    regex
  }

  fragment typeFrgmnt on TaskType {
    ...coreTypeFrgmnt
    marker_config {
      ...markersFrgmnt
    }
    conditional_activations {
      ...conditionsFrgmnt
    }
    restricted2users {
      id
      username
    }
  }

  ${markersFrgmnt.markersFrgmnt}
  ${conditionsFrgmnt.conditionsFrgmnt}
`;

export interface CoreTaskTypeFrmnt {
  id: string;
  name: string;
  instructions: string;
  regex: string | undefined;
  marker_config: markersFrgmnt.AnyMrkrCfg[];
  conditional_activations: conditionsFrgmnt.ConditionsFrgmntType[];
  restricted2users: {
    id: string;
    username: string;
  }[];
}

export interface CoreTaskTypeWithSubTypes extends CoreTaskTypeFrmnt {
  task_types: CoreTaskTypeWithSubTypes[];
}
