/* eslint-disable no-underscore-dangle */
import 'react-app-polyfill/ie11';
import * as React from 'react';
import ReactDOM from 'react-dom';
import { ApolloProvider } from '@apollo/client';
import { Provider } from 'react-redux';

import apolloClient from './apolloClient';
import reduxStore from './reduxStore';
import App from './App';

import './index.css';

const root = document.getElementById('root');
if (!root) throw new Error('Root element not found!');

// Removed due to issues:  socket={wsLink.subscriptionClient}
ReactDOM.render(
  <Provider store={reduxStore}>
    <ApolloProvider client={apolloClient}>
      <App />
    </ApolloProvider>
  </Provider>,
  root,
);
