import { getMappedCoords } from './getMappedCoords';
import { samePoint, roundCoords } from './positions';
import { XYpos } from '../types/XYpos';

export const dot = (
  ctxt: CanvasRenderingContext2D,
  center: XYpos,
  width: number,
  height: number,
  _size: number,
  fillStyle: string,
  activePoint: XYpos | undefined,
  sameFill = 'rgba(201, 145, 194, .5)',
) => {
  let size = _size;
  ctxt.beginPath();
  if (samePoint(center, activePoint)) {
    ctxt.fillStyle = sameFill; // eslint-disable-line
    size *= 1.25;
  } else {
    ctxt.fillStyle = fillStyle; // eslint-disable-line
  }
  const { x, y } = roundCoords(getMappedCoords(center, width, height));
  ctxt.arc(x, y, size, 0, Math.PI * 2, true);
  ctxt.fill();
  ctxt.closePath();
};

export default (
  ctxt: CanvasRenderingContext2D,
  center: XYpos,
  width: number,
  height: number,
  fillStyle: string,
  activePoint: XYpos | undefined,
) => {
  const diagonal = Math.sqrt((width + height) ** 2);
  const size = diagonal * 0.005;
  dot(ctxt, center, width, height, size, fillStyle, activePoint);
};
