interface Args {
  xCenter: number;
  canvasPixelWidth: number;
}

const getTextMidPosition = ({ xCenter, canvasPixelWidth }: Args) => {
  const offsetPixels = 10;
  let xpos = xCenter + offsetPixels;
  let align: CanvasRenderingContext2D['textAlign'] | undefined;
  if (xpos > canvasPixelWidth - canvasPixelWidth / 10) {
    xpos = xCenter - offsetPixels;
    // eslint-disable-next-line no-param-reassign
    align = 'right';
  }
  return { xpos, align };
};

export default getTextMidPosition;
