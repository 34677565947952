import { LabelState } from '../types';
import { AnyTool } from '../../utils/Markers/types';
import saveLabelReducer from './saveLabelReducer';

interface Props {
  taskId: string;
  tools: AnyTool[];
  state: LabelState;
}
export default (props: Props): LabelState => {
  const { tools, taskId, state } = props;
  let returnState = state;
  tools.forEach((tool) => {
    returnState = saveLabelReducer({ taskId, tool, state: returnState });
  });

  return returnState;
};
