import styled from 'styled-components';

const Conditions = styled.div`
  height: 70vh;
  margin-top: 10%;
  margin-bottom: 10%;
  overflow-y: auto;
  max-width: 800px;
  margin: auto;
`;

export default Conditions;
