import React, { lazy, Suspense, useMemo } from 'react';
import { BrowserRouter as Router, Route, Switch, RouteComponentProps } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import 'regenerator-runtime/runtime';
import { get } from 'lodash-es';
import { ThemeProvider } from 'styled-components';

import { WorkerRoute } from './components';
import AppLoading from './AppLoading';
import AppError from './AppError';

import './App.css';
import './style/style.css';
import useCurrentUser, { retrieveTaskData, CrntUsrCtxt } from './gql/useCurrentUser';
import themes from './themes';
import { CurrentUserType } from './gql/fragments';

const StartPage = lazy(() => import(/* webpackChunkName: "StartPage" */ './containers/StartPage'));
const LabelController = lazy(() => import(/* webpackChunkName: "Labeling" */ './containers/Label'));
const DemoController = lazy(() => import(/* webpackChunkName: "Demo" */ './containers/Demo'));
const PacsController = lazy(() => import(/* webpackChunkName: "PACS" */ './containers/PACS'));

const App = () => {
  /* eslint-disable react/display-name */
  const renderDemoController = useMemo(
    () => ({ match, history }: RouteComponentProps) => (
      <Suspense fallback={null}>
        <DemoController match={match} history={history} />
      </Suspense>
    ),
    [],
  );

  const renderLabelController = useMemo(
    () => ({ match, history }: RouteComponentProps) => (
      <Suspense fallback={null}>
        <LabelController match={match} history={history} />
      </Suspense>
    ),
    [],
  );

  const renderPacsController = useMemo(
    () => () => (
      <Suspense fallback={null}>
        <PacsController />
      </Suspense>
    ),
    [],
  );

  const renderStartPage = useMemo(
    () => () => (
      <Suspense fallback={null}>
        <StartPage />
      </Suspense>
    ),
    [],
  );

  const userQuery = useCurrentUser({ fetchPolicy: 'network-only' });

  const current: CurrentUserType | undefined = get(userQuery, 'data.user.current');

  const ctxtValues = {
    ...userQuery,
    current,
    current_task: retrieveTaskData(userQuery),
    siths: get(userQuery, 'data.user.SITHS'),
  };

  if (userQuery.loading) return <AppLoading />;
  if (userQuery.error) return <AppError />;

  let usrTheme = themes.Batmode;
  if (current) {
    const { theme_name: tm } = current.worker.settings.display;
    if (tm && tm in themes) {
      usrTheme = themes[tm];
    }
  }

  return (
    <Router>
      <CrntUsrCtxt.Provider value={ctxtValues}>
        <ThemeProvider theme={usrTheme}>
          <Switch>
            <WorkerRoute path={`${process.env.PUBLIC_URL || ''}/demo`} currentUserQuery={userQuery}>
              {renderDemoController}
            </WorkerRoute>
            <WorkerRoute
              path={`${process.env.PUBLIC_URL || ''}/label`}
              currentUserQuery={userQuery}
            >
              {renderLabelController}
            </WorkerRoute>
            <WorkerRoute path={`${process.env.PUBLIC_URL || ''}/pacs`} currentUserQuery={userQuery}>
              {renderPacsController}
            </WorkerRoute>
            <Route path={`${process.env.PUBLIC_URL || ''}/`}>{renderStartPage}</Route>
          </Switch>
        </ThemeProvider>
      </CrntUsrCtxt.Provider>
    </Router>
  );
};

export default App;
