import { produce } from 'immer';
import { SuggestedLabelsFrgmntType as SuggestedLabels } from '../../gql/fragments/task2label/suggestedLabels';
import { initData } from './helpers';
import { RulerTypes, MrkrTypes } from '../../types';
import { XYpos } from './types/XYpos';
import { TaskTypeFrgmnt } from '../../gql/fragments/task2label';

export interface Options {
  multiple?: boolean; // Deprecated
  min?: number;
  max?: number;
  required?: boolean;
  // eslint-disable-next-line camelcase
  each_img?: boolean;
}

export interface Props {
  [key: string]: unknown;
  task_type: TaskTypeFrgmnt;
  options: Options & { ruler?: RulerTypes };
  short_description: string;
  detailed_description?: string | undefined;
}

export interface CoreData {
  [key: string]: string | TaskTypeFrgmnt | MrkrTypes | boolean | unknown;
  short_description: string;
  detailed_description?: string | undefined;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options: any;
  task_type: TaskTypeFrgmnt;
  suggested: boolean;
  type: MrkrTypes;
  multiple: boolean | undefined;
  persistedPosition: number | undefined;
}

const init = {
  type: undefined,
  short_description: undefined,
  suggested: false,
};

export default abstract class Base<P extends Props, C extends CoreData> {
  data: C;

  constructor(props: P | Base<P, C>, type: MrkrTypes) {
    if (props instanceof Base) {
      this.data = props.data;
    } else {
      this.data = initData<C>(props, init);
    }
    this.data = produce<C>(this.data, (draft) => {
      draft.type = type;
    });
  }

  serialize(): C {
    return this.data;
  }

  get short_description() {
    return this.data.short_description;
  }

  get detailed_description() {
    return this.data.detailed_description;
  }

  get task_type() {
    return this.data.task_type;
  }

  get options() {
    return this.data.options;
  }

  get persistedPosition() {
    return this.data.persistedPosition;
  }

  get marker() {
    return this.data.type;
  }

  // Call this when the tool has been saved to the database
  setPersisted(index: number) {
    this.data = produce<C>(this.data, (draft) => {
      draft.persistedPosition = index;
    });
  }

  clone() {
    const clone = { ...this };
    Object.setPrototypeOf(clone, Object.getPrototypeOf(this));
    return clone;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/no-unused-vars
  setSuggested(vals: unknown): any {
    this.data = produce<C>(this.data, (draft) => {
      draft.suggested = true;
    });

    // Final instantiation should return a clone
    return this;
  }
  /* eslint-enable */

  requireDone(name = 'measure'): true | null {
    if (!this.isDone()) {
      if (!this.options.required) {
        return null;
      }
      throw new Error(
        `The ${name} '${this.short_description}' has to be done before preparing submission object`,
      );
    }
    return true;
  }

  // eslint-disable-line
  // eslint-disable-next-line class-methods-use-this
  isDone(): boolean {
    return true;
  }

  getBaseSubmissionObject() {
    return {
      short_description: this.short_description,
    };
  }

  // eslint-disable-next-line
  getIndex(point: XYpos): number {
    // eslint-disable-line
    return -1;
  }
  /* eslint-enable */

  // Protected function
  prFindTypeLabels(suggestedLabels: SuggestedLabels[]): SuggestedLabels | undefined {
    if (!suggestedLabels || !this.task_type) return undefined;

    const { id: classTypeId } = this.task_type;
    return suggestedLabels.find(({ task_type: { id } }) => id === classTypeId);
  }
}
