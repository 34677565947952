import Box from '../Box';
import Circle from '../Circle';
import Classification from '../Classification';
import ConnectedLine from '../ConnectedLine';
import Coordinate from '../Coordinate';
import IndependentLine from '../IndependentLine';
import Line from '../Line';
import Pen from '../Pen';
import { MrkrTypes } from '../../../types';
import { AnyTool } from '../types';

export const getToolName = (tool: AnyTool | undefined): MrkrTypes | undefined => {
  if (tool instanceof Box) {
    return 'Box';
  }

  if (tool instanceof Circle) {
    return 'Circle';
  }

  if (tool instanceof Classification) {
    return 'Classification';
  }

  if (tool instanceof Coordinate) {
    return 'Coordinate';
  }

  if (tool instanceof ConnectedLine) {
    return 'ConnectedLine';
  }

  if (tool instanceof IndependentLine) {
    return 'IndependentLine';
  }

  if (tool instanceof Line) {
    return 'Line';
  }

  if (tool instanceof Pen) {
    return 'Pen';
  }

  return undefined;
};
