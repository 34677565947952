import { produce } from 'immer';
import Base, { Props as BaseProps, CoreData as BaseCoreData, Options as BaseOptions } from './Base';
import { dot, initData } from './helpers';
import { RulerTypes, MrkrTypes } from '../../types';
import { XYpos } from './types/XYpos';
import { CoordinateWithDistance } from '../../gql/useCurrentTask';
import { DrawArguments, GetPointsArguments } from './types/general';
import extendPointWithDistance from './helpers/extendPointWithDistance';

export interface SubmissionObject {
  short_description: string;
  image: string;
}

export interface CoreData extends BaseCoreData {
  image: string | undefined;
  eachImg: boolean | undefined;
  min: number | undefined;
  max: number | undefined;
  avoidImgs: string[];
  movingPoint: XYpos | undefined;
  movingIndex: number | undefined;
  imageMrkr: true;
}

export interface Props extends BaseProps {
  image?: string;
  avoidImgs?: string[];
  options: BaseOptions & { ruler?: RulerTypes };
}

export default abstract class ImgMrkr<P extends Props, C extends CoreData> extends Base<P, C> {
  constructor(props: P, type: MrkrTypes) {
    const {
      image,
      options: { each_img: eachImg, multiple, min, max },
      avoidImgs = [],
    } = props;
    super(props, type);
    if (!(props instanceof ImgMrkr)) {
      // Add custom data from options
      this.data = initData<C>(
        {
          eachImg,
          multiple,
          min,
          max,
          image,
          avoidImgs,
          imageMrkr: true,
        },
        this.data,
      );
    }
  }

  get image() {
    return this.data.image;
  }

  get eachImg() {
    return this.data.eachImg;
  }

  get multiple() {
    return this.data.multiple;
  }

  get min() {
    return this.data.min;
  }

  get max() {
    return this.data.max;
  }

  get avoidImgs() {
    return this.data.avoidImgs;
  }

  setAvoidImgs(imgs: string[]) {
    this.data = produce<C>(this.data, (draft) => {
      draft.avoidImgs = imgs;
    });
  }

  get movingPoint() {
    return this.data.movingPoint;
  }

  get movingIndex() {
    return this.data.movingIndex;
  }

  setSuggested(
    // eslint-disable-next-line camelcase
    vals: { image: { id: string; original_name: string } } & { [key: string]: unknown },
  ) {
    this.setImage(vals.image.id);
    return super.setSuggested(vals);
  }

  setImage(image: string, orgName?: string) {
    if (this.avoidThisImg(image, orgName)) throw new Error(`The image ${image} isn't allowed`);
    this.data = produce<C>(this.data, (draft) => {
      draft.image = image;
    });
  }

  avoidThisImg(image: string, orgName?: string) {
    const { task_type: tt } = this;
    if (!tt) {
      return false;
    }

    if (tt.regex && orgName) {
      if (!orgName.match(new RegExp(tt.regex))) {
        return true;
      }
    }
    return this.avoidImgs.indexOf(image) >= 0;
  }

  getImgSubmissionObject() {
    if (!this.image) throw new Error('The image must be set before preparing submission object');
    return {
      ...super.getBaseSubmissionObject(),
      image: this.image,
    };
  }

  isDone() {
    return typeof this.image === 'string' && this.image.length > 0;
  }

  /**
   * Whether the tool can be applied to > 1 image
   */
  isMultiImage() {
    return this.eachImg || (this.max && this.max > 1) || this.multiple;
  }

  setMovingPoint(point: XYpos, index: number | undefined, imgId: string) {
    if (this.image === imgId) {
      this.data = produce<C>(this.data, (draft) => {
        draft.movingPoint = point;
        draft.movingIndex = index;
      });
    } else {
      this.unSetMovingPoint();
    }
  }

  unSetMovingPoint() {
    this.data = produce<C>(this.data, (draft) => {
      draft.movingPoint = undefined;
      draft.movingIndex = undefined;
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars, class-methods-use-this
  getPoints(args: GetPointsArguments = {}): Array<XYpos | undefined> {
    return [];
  }

  // eslint-disable-next-line @typescript-eslint/space-before-function-paren
  abstract setPoint(point: XYpos, index: number | undefined, imgId: string): unknown;

  // eslint-disable-next-line class-methods-use-this
  countUnsetPoints() {
    return 0;
  }

  draw({
    ctxt,
    width,
    height,
    scale,
    strokeStyle,
    fillStyle,
    circleProp = 0.004,
    activePoint,
  }: DrawArguments) {
    const diagonal = Math.sqrt(width ** 2 + height ** 2);
    const size = (diagonal * circleProp) / ((scale.x + scale.y) / 2);

    const clr = this.getStrokeStyle(strokeStyle || fillStyle);
    this.getPoints({ useMovingPoint: true })
      .filter((p): p is XYpos => !!p)
      .forEach((point) => dot(ctxt, point, width, height, size, clr, activePoint));
  }

  getStrokeStyle(strokeStyle: string | undefined): string {
    if (strokeStyle) return strokeStyle;
    return this.data.suggested ? 'rgba(204, 204, 0, 0.9)' : 'rgba(150, 255, 150, 0.9)';
  }

  getPointsWithinDistance(point: XYpos, detectionDistance: number): CoordinateWithDistance[] {
    // @ts-ignore - tasktype mismatch :-/
    return this.getPoints()
      .filter((p): p is XYpos => !!p)
      .map((innerPoint) => extendPointWithDistance(innerPoint, point))
      .filter((innerPoint) => innerPoint.distance < detectionDistance)
      .map((filteredPoint) => ({
        short_description: this.short_description,
        task_type: this.task_type,
        distance: filteredPoint.distance,
        x: filteredPoint.x,
        y: filteredPoint.y,
      }));
  }
}
