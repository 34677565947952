import * as React from 'react';
import { Tooltip, UncontrolledTooltipProps, ButtonProps } from 'reactstrap';
import FormattedText from './FormattedText';
import { StyledButton as Button } from './StyledButton';

interface Props extends ButtonProps {
  color?: string;
  tip: string;
  id: string;
  formatted?: boolean;
  children: React.ReactNode | string | number;
  placement?: UncontrolledTooltipProps['placement'];
}

let counter = 0;

const TooltipButton = (props: Props) => {
  const [counterId, setCounterId] = React.useState(-1);
  const [tooltipOpen, setTooltipOpen] = React.useState(false);
  const [activeTip, setActiveTip] = React.useState(false);

  React.useEffect(() => {
    counter += 1;
    setCounterId(counter);
    setActiveTip(true);
    return () => {
      setActiveTip(false);
    };
  }, [setCounterId]);

  const toggle = React.useCallback(() => {
    if (!activeTip) return;
    setTooltipOpen(!tooltipOpen);
  }, [activeTip, setTooltipOpen, tooltipOpen]);

  const {
    color = 'light',
    id,
    children,
    tip,
    placement = 'bottom',
    formatted = true,
    ...other
  } = props;
  const componentId = `Tooltip_${id.replace(/\W/g, '_')}_${counterId}`;

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Button color={color} id={componentId} {...other}>
      {children}
      {activeTip && (
        <Tooltip placement={placement} isOpen={tooltipOpen} target={componentId} toggle={toggle}>
          {formatted && <FormattedText value={tip} />}
          {!formatted && tip}
        </Tooltip>
      )}
    </Button>
  );
};

export default React.memo(TooltipButton);
