import ImgMrkr from '../ImgMrkr';
import Box, { CoreData as BoxCoreData } from '../Box';
import Circle, { CoreData as CircleCoreData } from '../Circle';
import Classification, { CoreData as ClassificationCoreData } from '../Classification';
import ConnectedLine, { CoreData as ConnectedLineCoreData } from '../ConnectedLine';
import Coordinate, { CoreData as CoordinateCoreData } from '../Coordinate';
import IndependentLine, { CoreData as IndependentLineCoreData } from '../IndependentLine';
import Line, { CoreData as LineCoreData } from '../Line';
import Pen, { CoreData as PenCoreData } from '../Pen';

import getImgs2Mark4Tool from '../../../containers/Viewer/helpers/getImgs2Mark4Tool';
import { AnyTool } from '../types';
import { MrkrTypes } from '../../../types';
import { GeneralTaskFrgmntType } from '../../../gql/helpers/normalizeTypes';

type ExtendMarkerWith<T extends { type: MrkrTypes }> = { marker: T['type'] } & T;

export type ToolDefinitions =
  | ExtendMarkerWith<BoxCoreData>
  | ExtendMarkerWith<CircleCoreData>
  | ExtendMarkerWith<ClassificationCoreData>
  | ExtendMarkerWith<ConnectedLineCoreData>
  | ExtendMarkerWith<CoordinateCoreData>
  | ExtendMarkerWith<IndependentLineCoreData>
  | ExtendMarkerWith<LineCoreData>
  | ExtendMarkerWith<PenCoreData>;

const createTool = (
  toolDefinition: ToolDefinitions,
  task: GeneralTaskFrgmntType,
  toolLabels?: AnyTool[],
): AnyTool => {
  let newTool;
  const { marker: markerDef } = toolDefinition;
  switch (markerDef) {
    case 'Box': {
      const { marker, ...defs } = toolDefinition;
      newTool = new Box(defs);
      break;
    }
    case 'Circle': {
      const { marker, ...defs } = toolDefinition;
      newTool = new Circle(defs);
      break;
    }
    case 'Classification': {
      const { marker, ...defs } = toolDefinition;
      newTool = new Classification(defs);
      break;
    }
    case 'ConnectedLine': {
      const { marker, ...defs } = toolDefinition;
      newTool = new ConnectedLine(defs);
      break;
    }
    case 'Coordinate': {
      const { marker, ...defs } = toolDefinition;
      newTool = new Coordinate(defs);
      break;
    }
    case 'IndependentLine': {
      const { marker, ...defs } = toolDefinition;
      newTool = new IndependentLine(defs);
      break;
    }
    case 'Line': {
      const { marker, ...defs } = toolDefinition;
      newTool = new Line(defs);
      break;
    }
    case 'Pen': {
      const { marker, ...defs } = toolDefinition;
      newTool = new Pen(defs);
      break;
    }
    default:
      throw new Error(`Could not instantiate the marker of type ${markerDef}`);
  }

  if (newTool instanceof ImgMrkr) {
    const allImgs = task.images.map((i) => i.id);
    if (toolLabels) {
      const imgsLeft = getImgs2Mark4Tool(newTool, task, toolLabels);
      newTool.setAvoidImgs(allImgs.filter((i) => !imgsLeft.includes(i)));
    }
  }

  return newTool;
};

export default createTool;
