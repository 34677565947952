import { produce } from 'immer';
import ImgMrkr, { Props as ImgProps, CoreData as ImgCoreData } from './ImgMrkr';
import { samePoint } from './helpers';
import { XYpos } from './types/XYpos';
import { SuggestedCoordinate } from '../../types';
import { GetPointsArguments } from './types/general';

interface CoordinateSubmission extends XYpos {
  short_description: string;
  image: string;
}

export interface CoreData extends ImgCoreData {
  x: number | undefined;
  y: number | undefined;
  type: 'Coordinate';
}

export interface Props extends ImgProps {
  x?: number;
  y?: number;
}

export default class Coordinate extends ImgMrkr<Props, CoreData> {
  constructor(props: Props) {
    super(props, 'Coordinate');
  }

  get x() {
    return this.data.x;
  }

  get y() {
    return this.data.y;
  }

  getPoint(): XYpos | undefined {
    if (typeof this.x !== 'number' || typeof this.y !== 'number') {
      return undefined;
    }
    return { x: this.x, y: this.y };
  }

  getSubmissionObject(): CoordinateSubmission {
    const [point] = this.getPoints({ useMovingPoint: false });
    const obj = super.getImgSubmissionObject();
    if (!point || !obj) throw new Error('The x & y must be set before preparing submission object');
    const ret = {
      ...obj,
      ...point,
    };
    return ret;
  }

  setSuggested(vals: SuggestedCoordinate): Coordinate {
    super.setSuggested(vals);
    this.data = produce<CoreData>(this.data, (draft) => {
      draft.x = vals.x;
      draft.y = vals.y;
    });
    return this.clone();
  }

  isDone(): boolean {
    if (!super.isDone()) return false;

    if (typeof this.x !== 'number' || typeof this.y !== 'number') return false;

    return true;
  }

  getIndex(point: XYpos): number {
    if (samePoint(point, this.getPoint())) {
      return 0;
    }

    return super.getIndex(point);
  }

  setPoint(point: XYpos, index: number | undefined, imgId: string): Coordinate {
    if (!this.image) this.setImage(imgId);
    if (this.image !== imgId) return this;
    this.data = produce<CoreData>(this.data, (draft) => {
      draft.suggested = false;
      draft.x = point.x;
      draft.y = point.y;
    });

    return this.clone();
  }

  getPoints({ useMovingPoint = false }: GetPointsArguments = {}): (XYpos | undefined)[] {
    let point = this.getPoint();
    if (useMovingPoint) {
      if (this.movingPoint && this.movingIndex === 0) {
        point = this.movingPoint;
      }
    }
    return [point];
  }
}
