import { ClassificationProbability } from '../../../../../../gql/fragments/task2label/suggestedLabels';

export default (
  shortDescription?: string,
  suggestedClasses?: ClassificationProbability[],
): number | undefined => {
  if (!suggestedClasses) return undefined;

  const match = suggestedClasses.find(({ name }) => name === shortDescription);
  if (!match) return undefined;

  return match.probability;
};
