import { produce } from 'immer';

interface InputData {
  [key: string]: unknown;
}

const validPrimitives = ['string', 'number', 'undefined', 'boolean', 'bigint', 'symbol'];

type InitFnType = <T extends InputData>(draftData: InputData, initData: InputData) => T;
// @ts-ignore - not ideal but there is no obvious other solution :-S
const initData: InitFnType = produce((draft: InputData, data: InputData) => {
  Object.keys(data).forEach((key) => {
    if (draft[key] === undefined) {
      const elmnt = data[key];
      if (elmnt instanceof Array) {
        draft[key] = [...elmnt];
      } else if (elmnt instanceof Object) {
        draft[key] = { ...elmnt };
      } else if (elmnt === null) {
        draft[key] = elmnt;
      } else if (!validPrimitives.includes(typeof elmnt)) {
        throw Error(
          `Invalid initialization attempt with ${elmnt} - neither array, object or primitive`,
        );
      } else {
        draft[key] = elmnt;
      }
    }
  });
});

export default initData;
