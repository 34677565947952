import * as React from 'react';
import styled from 'styled-components';

const Center = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  position: relative;
  bottom: 0px;
  top: 0px;
  position: absolute;
`;

const Filler = styled.div<{ grow: number }>`
  flex-grow: ${({ grow }) => grow};
`;

const Item = styled.div`
  max-width: 50%;
  overflow: auto;
  flex-grow: 1;
  min-height: 0;
`;

//  positions the container at an optimal vertical offset - slightly above the center
const GoldenCenter = ({ children }: { children: React.ReactNode }) => (
  <Center>
    <Filler grow={1} />
    <Item>{children}</Item>
    <Filler grow={2} />
  </Center>
);

export default GoldenCenter;
