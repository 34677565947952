import produce from 'immer';
import requireToolName from './requireToolName';
import { ImgMrkr } from '../../utils/Markers';
import { LabelState } from '../types';
import { MarkerData, AnyTool } from '../../utils/Markers/types';
import Classification from '../../utils/Markers/Classification';
import findLabel from './findLabel';

interface Props {
  taskId: string;
  tool: AnyTool;
  state: LabelState;
}

export default ({ taskId, tool, state }: Props) =>
  produce(state, (draft) => {
    if (tool instanceof ImgMrkr) {
      // Clear the avoidImgs once the image has been saved
      tool.setAvoidImgs([]);
    }
    const toolName = requireToolName(tool);
    const { task_type: taskType } = tool;
    if (!taskType) {
      throw new Error(
        `The tool ${tool.short_description} doesn't have a task
                   type - even free markers should have a type.`.replace(/[ ]{2,}/g, ' '),
      );
    }
    const taskState = draft[taskId];

    if (taskState) {
      // Find the label with the corresponding task type of the tool
      let newIndx: number = taskState.findIndex(findLabel(taskType.id));
      if (newIndx < 0) {
        // Add the current task type if it wasn't already in the data
        newIndx = taskState.push({ task_type: taskType }) - 1;
      }

      // The update object registers the changes that we want to do to the stack
      const existingLbls: MarkerData[] | undefined = taskState[newIndx][toolName];
      if (existingLbls) {
        let idx = tool.persistedPosition;
        if (idx !== undefined) {
          const lbl = existingLbls[idx];
          if (!lbl) {
            const len = existingLbls.length - 1;
            throw new Error(`The index '${idx}' for the label is not valid (should be 0:${len})`);
          } else if (lbl.short_description !== tool.short_description) {
            // eslint-disable-next-line no-console
            console.warn(state);
            throw new Error(
              `The ${idx} has a different description, '${lbl.short_description}', expected '${tool.short_description}'`,
            );
          }
        } else if (!(tool instanceof Classification) && !tool.multiple) {
          idx = existingLbls.findIndex((lbl) => {
            if (lbl.short_description !== tool.short_description) return false;
            if (lbl.imageMrkr && tool instanceof ImgMrkr) {
              // If the tool can be applied to multiple images and the current
              // label doesn't match then we save this as an additional label
              // and won't overwrite the previous label
              if (tool.isMultiImage() && lbl.image !== tool.image) return false;
            }
            return true;
          });
        }

        // We have an index then we replace the old idx with the new tool
        // otherwise we push a new tool on top of the previous stack.
        // Once stored in the database multiple should it should no longer
        // instantiate new object, i.e. a tool enters a persisted state
        // on entering the store and can thus never be pushed as a new tool.
        if (idx !== undefined && idx >= 0) {
          existingLbls[idx] = tool.serialize();
        } else {
          tool.setPersisted(existingLbls.length);
          existingLbls.push(tool.serialize());
        }
      } else {
        // Initiate a the tool group
        tool.setPersisted(0);
        // @ts-ignore - TODO: make CoreData somewhat smarter...
        taskState[newIndx][toolName] = [tool.serialize()];
      }
    } else {
      // Initiate the tool set for the task
      tool.setPersisted(0);
      draft[taskId] = [
        {
          task_type: taskType,
          [toolName]: [tool.serialize()],
        },
      ];
    }
  });
