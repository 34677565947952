import { Action as ReduxAction } from 'redux';
import PNG from 'pngjs3';
import { ImgFrgmntType } from '../../gql/fragments/task2label/img';

export const SETUP_IMAGES = 'SETUP_IMAGES';
export const STORE_LOADED_IMAGE = 'STORE_LOADED_IMAGE';
export const LOADING_IMAGE = 'LOADING_IMAGE';
export const FAILED_LOAD_IMAGE = 'FAILED_LOAD_IMAGE';
export const UNLOAD_IMAGE = 'UNLOAD_IMAGE';
export const NO_AVAILABLE_IMG_2_SELECT = 'NO_AVAILABLE_IMG_2_SELECT';
export const SET_MAX_RESOLUTION = 'SET_MAX_RESOLUTION';
export const SET_ONLY_FULL_RESOLUTION = 'SET_ONLY_FULL_RESOLUTION';

// Not really an image action but the text that indicates that an image
// should be downloaded in full resolution
export const FULL_IMAGE_SIZE = 'fullSize';

export interface SetMaxResolutionAction extends ReduxAction {
  type: 'SET_MAX_RESOLUTION';
  maxResolution: undefined | number;
}

export interface SetOnlyFullResolutionAction extends ReduxAction {
  type: 'SET_ONLY_FULL_RESOLUTION';
  onlyFullResolution: boolean;
}

export interface LoadingImagAction extends ReduxAction {
  type: 'LOADING_IMAGE';
  href: string;
  sizeDescription: string;
}

export interface StoreLoadedImageAction extends ReduxAction {
  type: 'STORE_LOADED_IMAGE';
  taskId: string;
  href: string;
  sizeDescription: string;
  png: PNG;
}

export interface FailedLoadingImageAction extends ReduxAction {
  type: 'FAILED_LOAD_IMAGE';
  href: string;
  sizeDescription: string;
}

export interface SetupImagesAction extends ReduxAction {
  type: 'SETUP_IMAGES';
  imgs: ImgFrgmntType[];
}

export interface UnloadImageAction extends ReduxAction {
  type: 'UNLOAD_IMAGE';
  href: string;
  taskId: string;
}

export type ImageActions =
  | LoadingImagAction
  | StoreLoadedImageAction
  | FailedLoadingImageAction
  | SetupImagesAction
  | UnloadImageAction
  | SetOnlyFullResolutionAction
  | SetMaxResolutionAction;
