import { XYpos, XYposWithType } from '../types/XYpos';

export const purePosition = ({ x, y }: XYposWithType | XYpos): XYpos => ({ x, y });

export const samePoint = (pos1?: XYpos | undefined, pos2?: XYpos | undefined): boolean => {
  if (!pos1 || !pos2) return false;

  if (Math.round(pos1.x * 1000) !== Math.round(pos2.x * 1000)) return false;
  if (Math.round(pos1.y * 1000) !== Math.round(pos2.y * 1000)) return false;

  return true;
};

export const roundCoords = ({ x, y }: XYpos): XYpos => ({
  x: Math.round(x),
  y: Math.round(y),
});
