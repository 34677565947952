/* eslint-disable @typescript-eslint/naming-convention */
import { ApolloClient, split, from, HttpLink, InMemoryCache, Operation } from '@apollo/client';
import { onError, ErrorResponse } from '@apollo/client/link/error';
import { RetryLink } from '@apollo/client/link/retry';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';
import { env } from './config';

// eslint-disable-next-line @typescript-eslint/naming-convention
const { GRAPHQL_URI, SUBSCRIPTION_URI } = env;
const isSubscription = ({ query }: Operation) => {
  // @ts-ignore - operation issue?
  const { kind, operation } = getMainDefinition(query);
  return kind === 'OperationDefinition' && operation === 'subscription';
};

const errorHandler = ({ graphQLErrors, networkError }: ErrorResponse) => {
  if (graphQLErrors) {
    graphQLErrors.map(({ message, locations, path }) =>
      // eslint-disable-next-line no-console
      console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`),
    );
  }
  // eslint-disable-next-line no-console
  if (networkError) console.log(`[Network error]: ${networkError}`);
};

const httpLink = new HttpLink({
  uri: GRAPHQL_URI,
  credentials: 'include',
});

const wsLink = new WebSocketLink({
  uri: SUBSCRIPTION_URI,
  options: {
    reconnect: true,
    timeout: 20000,
    lazy: true,
  },
});

const splitLink = split(isSubscription, wsLink, httpLink);

// Remove websocket - fixes bug:
// https://stackoverflow.com/questions/14140414/websocket-was-interrupted-while-page-is-loading-on-firefox-for-socket-io
window.addEventListener('beforeunload', () => {
  // @ts-ignore - private in typescript
  wsLink.subscriptionClient.close();
});

const retry = new RetryLink();

const link = from([retry, onError(errorHandler), splitLink]);

const baseMergeFn = {
  merge(existing: Record<string, unknown>, incoming: Record<string, unknown>) {
    if (!existing) {
      return incoming;
    }
    return { ...existing, ...incoming };
  },
};
const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        user: baseMergeFn,
      },
    },
    Worker: {
      fields: {
        statistics: baseMergeFn,
      },
    },
  },
});

export default new ApolloClient({
  link,
  cache,
});
